import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar';
import { Container, Paper, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { AppHelper } from '../AppHelper';
import Fab from '@mui/material/Fab';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import PhoneNumber from 'libphonenumber-js';
import { useProperty } from '../contexts/context-property';

function PropertyListItems({ items, funOnClick, calendarActive }) {
  return (
    <List>
      {items.map((item, index) => (
        <ListItem key={item.id} disablePadding>
          <ListItemButton onClick={() => funOnClick(item)}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={item.n} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default function PagePropertyCreated() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [viewCode, setViewCode] = useState('NEW');
  const [countryList, setCountryList] = useState([]);
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [readingData, setReadingData] = useState(false);
  const [records, setRecords] = useState([]);
  
  const {property, setProperty} = useProperty();
  const [msgText, setMsgText] = useState('');
  const [validPhone, setValidPhone] = useState(false);

  useEffect(()=>{
    if(!property){
      navigate('/property/list');
      return;
    }
    setMsgText(`Hi ${property.s}, please fill out your schedule availability so we can sell your property at ${property.n}!
    https://app.dingdong.asia/land/s/${property.sid}`);
    if(property.sp && property.sp.length>1){
      const _phoneNoParsed = PhoneNumber(property.sp);
      if (_phoneNoParsed && _phoneNoParsed.isValid()) {
        setValidPhone(true);
      }
    }
  }, []);

  useEffect(()=>{
    setReadingData(true);
    const fetchRecords = async() =>{
      setReadingData(false);
      var _res = await AppHelper.api('property/list', 'POST', {uid:oUser.uid});
      if(_res.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the list!'});
        return;
      }
      setRecords(_res.data);
    };
    fetchRecords();
  }, []);

  useEffect(()=>{
    const fetchCountryList = async() =>{
      countryList.splice(0);
      var _resCountries = await AppHelper.api('countries', 'GET');
      var _countryList = [];
      for (const [code, name] of Object.entries(_resCountries)) {
        _countryList.push({code, name: `${name} (${code})`});
      }
      setCountryList(_countryList);
    };
    fetchCountryList();
  }, []);

  const onAdd = async () => {
    setViewCode('NEW');
  };
  const recordItemClick = (arg) => {
  };
  const msgTextChange = (arg) =>{
    //console.log(arg);
    //setMsgBox(arg.target.value);
  };
  const go2Home = ()=>{
    navigate('/property/detail/' + property.pid);
  };
  const onCopy = ()=>{
    navigator.clipboard.writeText(msgText);
    setMsgBox({...msgBox, open: true, msg: 'Copied to clipboard!'});
  };
  const onWhatsapp = async()=>{
    console.log('msg ', property.pid, property);
    var _res = await AppHelper.api('seller/notify/initial', 'POST', {phoneNo:property.sp, pid:property.pid, ag_n:oUser.displayName, sid:property.sid, s_n:property.s, p_n:property.n});
    if(_res.status !== 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to send the Message!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Message is sent!'});
  }

  return (
    <div>
      <AppTopBar2 />
      {viewCode=='NO'? (
        readingData?(
          <div>Loading..</div>
        ):(
          (records && records.length>0)?(
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '90vh' }}
            >
              <Grid item xs={3}>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItem disablePadding>
                    <ListItemText primary={'Property List (' + records.length + ')'} />
                  </ListItem>
                  {
                    (!records || records.length<1)?(
                      <div>No record found! {records.length}</div>
                    ):(
                      <PropertyListItems items={records} funOnClick={recordItemClick} />
                    )
                  }
                </Box>
              </Grid>
              <Grid item xs={3} pt={1.5}>
                <Box sx={{width: '100%'}} m={.5} display="flex" justifyContent="flex-end">
                  <Button onClick={onAdd} color="info" variant="outlined">ADD</Button>
                </Box>
              </Grid>
            </Grid>
          ):(
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="80vh"
            >
            <Fab onClick={onAdd} sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
              <div>
                <div>ADD</div>
                <div>Listing</div>
              </div>
            </Fab>
            </Box>
          )
        )
      ):(
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'} }} elevation={3}>
            {property && 
            <Box m={3} style={{color:'#777'}}>
              <Grid>
                <Grid item style={{color:'#666', fontWeight:'bold'}}>
                  CONGRATULATIONS!
                </Grid>
                <Grid item mt={2}>
                  You have successfully created a listing <span style={{color:'#000', fontWeight:'bold'}}>{property.n}</span> for <span style={{color:'#000', fontWeight:'bold'}}>{property.s}</span>.
                </Grid>
                <Grid item mt={1}>
                  Share this message to <span style={{color:'#000', fontWeight:'bold'}}>{property.s}</span> so he can indicate his availability.
                </Grid>
                <Grid container mt={5}>
                  <Grid item xs={9}>
                    <textarea onChange={msgTextChange} value={msgText} rows={7} style={{width:'100%', textAlign:'left', padding:'5px', border: '1px solid #d3d3d3'}} ></textarea>
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" justifyContent="flex-end" alignItems='center' flexDirection='column'>
                      <Fab onClick={onCopy} sx={{width:'4rem', height:'4rem'}} color="secondary" aria-label="Login">
                        <ContentCopyIcon />
                      </Fab>
                      {/* {validPhone && <Fab onClick={onWhatsapp} sx={{width:'4rem', height:'4rem', marginTop:'3px'}} color="" aria-label="Login">
                        <img src="/img/WhatsApp_icon.png.webp" alt="Send Whatsapp" style={{width:'75%'}} />
                      </Fab>} */}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container mt={3}>
                  <Grid item xs={7}>
                      <Box display="flex" justifyContent="flex-start">
                        {validPhone && <Button onClick={onWhatsapp} color="info" endIcon={<SendIcon />} variant="contained">Notify Seller</Button>}
                      </Box>
                  </Grid>
                  <Grid item xs={5}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={go2Home} color="info" variant="contained">BACK TO LISTING</Button>
                      </Box>
                  </Grid>
                </Grid>
              </Grid>

            </Box>
            }
          </Paper>
        </Container>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </div>
  );
}
