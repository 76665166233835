import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReviewsIcon from '@mui/icons-material/Reviews';

export default function AppFooterFeedback() {
  const navigate = useNavigate();

  const go2Feedback = () => {
    navigate('/feedback');
  }

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        // value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
      >
        <BottomNavigationAction onClick={go2Feedback} label="FEEDBACK" icon={<ReviewsIcon />} />
      </BottomNavigation>
    </Paper>
  );
}