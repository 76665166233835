import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar';
import AppLoading from './common/app-loading';
import { Container, Paper, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Button, Select, MenuItem, FormControl, InputLabel, TextField, Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import PhoneNumber from 'libphonenumber-js';
import { AppHelper } from '../AppHelper';

function CalendarListItems({ items, funOnClick, calendarActive }) {
  return (
    <List>
      {items.map((item) => (
        <ListItem key={item.summary} style={{backgroundColor: calendarActive==item.id?'#b7b7d7':'inherit'}} disablePadding>
          <ListItemButton onClick={() => funOnClick(item)}>
            <ListItemIcon>
              <CalendarMonthIcon sx={{ color: item.backgroundColor }} />
            </ListItemIcon>
            <ListItemText primary={item.summary} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default function CalendarList() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [viewCode, setViewCode] = useState('NO');
  const [isLoading, setIsLoading] = useState(false);
  const [calendarList, setCalendarList] = useState([]);
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [calendarActive, setCalendarActive] = useState('');

  var _calendarList = [];
  useEffect(()=>{
    const fetcthCalenderList = async()=>{
      setIsLoading(true);
      _calendarList.splice(0);
      var _arg = { uid: oUser.uid, accessToken: oUser.accessToken };
      AppHelper.api('user/calendar', 'POST', _arg).then(_resCalendar=>{
        setIsLoading(false);
        if(_resCalendar.status !== 1){
          setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load calendar list. Please try logout and login again!'});
        }
        else{
          _resCalendar.data.items.forEach(_item => {
            if(_item.accessRole === 'owner'){
              _item.isPrimary = _item.primary ? 1 : 0;
              _calendarList.push(_item);
            }
          });
          _calendarList = AppHelper.sortList(_calendarList, 'isPrimary', 2);
          setCalendarList(_calendarList);
          console.log(_calendarList);

          AppHelper.api('user/calendar/get', 'POST', {uid: oUser.uid}).then(_res=>{
            if(_res.status !== 1){
              setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the user selected calendar!'});  
              return;
            }
            if(_res.data && _res.data.calendarId)
              setCalendarActive(_res.data.calendarId);
            else if(_calendarList.length>0){
              //setCalendarActive('');
              calendarItemClick(_calendarList[0]);
            }
          });
        }
      });
    };
    fetcthCalenderList();
  }, []);

  const calendarItemClick = async(itemCalendar) =>{
    var _res = await AppHelper.api('user/calendar/set', 'POST', { 
      uid: oUser.uid, 
      data:{
        calendarId: itemCalendar.id, 
        calendarName: itemCalendar.summary,
        timeZone: itemCalendar.timeZone
      }
    });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save the selected calendar!'});
      return;
    }
    setCalendarActive(itemCalendar.id);
    setMsgBox({...msgBox, open: true, msg: `SUCCESS: '${itemCalendar.summary}' is saved as selected calendar!`});
  }
  const onNext = (event) => {
    if(calendarActive==''){
      setMsgBox({...msgBox, open: true, msg: `Please select one calendar to proceed!`});
      return;
    }
    navigate('/property/list');
  };

  return (
    <div>
      <AppTopBar2 />
      {viewCode=='NO'? (
        isLoading?(
          <AppLoading />
        ):(
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'80vh', xs:'75vh'} }} elevation={3}>
              <ListItem disablePadding>
                <ListItemText primary="Choose calendar to connect" />
              </ListItem>
              <Divider />
              <nav aria-label="main mailbox folders">
                {
                  (!calendarList || calendarList.length<1)?(
                    <div>No calendar found! {calendarList.length}</div>
                  ):(
                    <CalendarListItems items={calendarList} funOnClick={calendarItemClick} calendarActive={calendarActive} />
                  )
                }
              </nav>
              <Box display='flex' justifyContent='flex-end'>
                <Button onClick={onNext} color="info" variant="outlined">Next</Button>
              </Box>
            </Paper>
          </Container>
        )
      ):(
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
        >
        </Box>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </div>
  );
}
