import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

firebase.initializeApp({
  "projectId": "mindful-origin-398206",
  "appId": "1:223546144272:web:e4b15863c52274e4e53c95",
  "storageBucket": "mindful-origin-398206.appspot.com",
  "locationId": "asia-southeast1",
  "apiKey": "AIzaSyBmOT3DQGYQomMn0BOOAeVNu2NzWb_1jjo",
  "authDomain": "mindful-origin-398206.firebaseapp.com",
  "messagingSenderId": "223546144272",
  "measurementId": "G-4WLGEV4BWM"
});

const db = firebase.firestore();
const auth = firebase.auth;
auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
.then(() => {
  // User signed in and persistence set
})
.catch((error) => {
  console.error('Error setting persistence:', error);
});

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  //db.useEmulator('localhost', 8080);
  db.useEmulator('localhost', 9088);
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export default firebase;
export { db, auth };
