import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar';
import { Paper, Checkbox, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Select, MenuItem, FormControl, InputLabel, TextField, Stack, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { AppHelper } from '../AppHelper';
import Fab from '@mui/material/Fab';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import PhoneNumber from 'libphonenumber-js';
import { useProperty } from '../contexts/context-property';

function PropertyListItems({ items, funOnClick, calendarActive }) {
  return (
    <List>
      {items.map((item, index) => (
        <ListItem key={item.id} disablePadding>
          <ListItemButton onClick={() => funOnClick(item)}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={item.n} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default function PageBuyerAdded() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { bid } = useParams();
  const isPhoneVerifed = oUser && oUser.phoneNumber && oUser.phoneNumber.length>0;
  const [viewCode, setViewCode] = useState('NEW');
  const [isAgent, setIsAgent] = useState(false);
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [readingData, setReadingData] = useState(false);
  const [record, setRecord] = useState({n:'-'});
  
  const {property, setProperty} = useProperty();
  const [msgText, setMsgText] = useState('');
  const [validPhone, setValidPhone] = useState(false);

  useEffect(()=>{
    if(!property || !property.b){
      navigate('/home');
      return;
    }
    var _arr = property.b.filter(r=>r.bid == bid);
    if(_arr.length<1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Buyer Info!'});
      navigate('/home');
      return;
    }
    var _rec = _arr[0];
    setRecord(_rec);
    if(_rec.ph && _rec.ph.length>1){
      const _phoneNoParsed = PhoneNumber(_rec.ph);
      if (_phoneNoParsed && _phoneNoParsed.isValid()) {
        setValidPhone(true);
      }
    }
    setIsAgent(_rec.ag === 1);
    setMsgText(`Hi ${_rec.n}, please fill out your schedule availability so we can arrange a viewing at ${property.n}!
    https://app.dingdong.asia/land/b/${_rec.bid}`)
  }, []);
  const msgTextChange = (arg) =>{
    //console.log(arg);
    //setMsgBox(arg.target.value);
  };
  const go2Home = ()=>{
    navigate('/property/detail/' + property.pid);
  };
  const onCopy = ()=>{
    navigator.clipboard.writeText(msgText);
    setMsgBox({...msgBox, open: true, msg: 'Copied to clipboard!'});
  };
  const onWhatsapp = async()=>{
    var _res = await AppHelper.api('buyer/notify/initial', 'POST', {phoneNo:record.ph, uid:property.uid, pid:property.id, ag_n:oUser.displayName, bid:record.bid, b_n:record.n, p_n:property.n});
    if(_res.status !== 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to send the Message!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Message is sent!'});
  }

  return (
    <div>
      <AppTopBar2 />
      {viewCode=='NO'? (
        readingData?(
          <div>Loading..</div>
        ):(
          <div></div>
        )
      ):(
        <Box
          display="flex"
          justifyContent="center" alignItems="center" minHeight="80vh"
        >
          <Paper elevation={3}>
            <Box m={3} style={{color:'#777'}}>
              <Grid>
                <Grid item style={{color:'#666', fontWeight:'bold'}}>
                  CONGRATULATIONS!
                </Grid>
                <Grid item mt={2}>
                  You have successfully added a buyer {isAgent?'agent ':''} for <span style={{color:'#000'}}>{property? property.n: ''}</span>.
                </Grid>
                <Grid item mt={1}>
                  Share this message to <span style={{color:'#000'}}>{record.n}</span> so he can indicate his availability.
                </Grid>
                <Grid container mt={5}>
                  <Grid item xs={9}>
                    <textarea onChange={msgTextChange} value={msgText} rows={7} style={{width:'100%', textAlign:'left', padding:'5px', border: '1px solid #d3d3d3'}} ></textarea>
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" justifyContent="flex-end" alignItems='center' flexDirection='column'>
                      <Fab onClick={onCopy} sx={{width:'4rem', height:'4rem'}} color="secondary" aria-label="Login">
                        <ContentCopyIcon />
                      </Fab>
                      {/* {validPhone && <Fab onClick={onWhatsapp} sx={{width:'4rem', height:'4rem', marginTop:'3px'}} color="" aria-label="Login">
                        <img src="/img/WhatsApp_icon.png.webp" alt="Send Whatsapp" style={{width:'75%'}} />
                      </Fab>} */}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container mt={3}>
                  <Grid item xs={7}>
                      <Box display="flex" justifyContent="flex-start">
                        {validPhone && 
                          <Button onClick={onWhatsapp} color="info" endIcon={<SendIcon />} variant="contained">Notify Buyer</Button>}
                      </Box>
                  </Grid>
                  <Grid item xs={5}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button onClick={go2Home} color="info" variant="contained">BACK TO LISTING</Button>
                      </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </div>
  );
}
