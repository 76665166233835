import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup, 
  BottomNavigation, BottomNavigationAction,
  IconButton, Button, MenuItem, Grid 
} from '@mui/material';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { AppHelper } from '../AppHelper';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AppLoading from './common/app-loading';
import { useProperty } from '../contexts/context-property';
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

function CopyPopup(props){
  const { onClose, open, copyDate, copyToEveryday, copyToAllWeekdays, daysTotal } = props;
  const handleClose = () =>{ onClose() };
  const handleListItemClick = (value) =>{ onClose(value) };
  
  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>REPLICATE SCHEDULE</DialogTitle>
      <Grid container>
        <Grid item xs={2}>
          <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <span style={{fontWeight:'bold', fontSize:'1.5rem'}}>{copyDate.n}</span>
              <div>{copyDate.n2}</div>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={10}>
          {copyDate.hrList.map((hr,iH)=>(
            <Button key={hr.v} color="info" variant={hr.a===1?'contained':'outlined'} 
              sx={{paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:0, marginRight:0.2, marginBottom:0.2}}
            >
              {hr.n}
            </Button>
          ))}
        </Grid>
        <Grid item xs={12} p={2} pt={3} pb={0.5}>
          <Button onClick={()=>{copyToEveryday()}} variant="contained" sx={{width:'100%'}}>TO EVERYDAY</Button>
        </Grid>
        <Grid item xs={12} p={2} pt={0} pb={0.5}>
          { daysTotal>7 &&
            <Button onClick={()=>{copyToAllWeekdays()}} variant="contained" sx={{width:'100%'}}>TO EVERY {copyDate.dddd}</Button>
          }
        </Grid>
        <Grid item xs={12} p={2} pt={0} pb={0.5}>
          <Button onClick={handleClose} sx={{width:'100%'}}>CANCEL</Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
CopyPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  copyDate: PropTypes.object.isRequired
};
function MonthDdlItems({items, funOnClick}){
  return(
    <div>
      {items.map((item, index) => (
        <MenuItem key={item.v} onClick={()=>funOnClick(item)} disableRipple>
          {item.n}
        </MenuItem>
      ))}
    </div>
  );
}
function DateListItems({ items, funOnCheckDate, funOnHourClick, funOnCopy }) {
  return (
    <Box>
      {items.map((item, index) => (
        <Grid key={item.v} container>
          <Grid item xs={12}>
            <Grid container sx={{border:'1px solid #efefef', backgroundColor:'#fff', borderRadius:'8px'}} mb={0.3} p={1}>
              <Grid item xs={2}>
                <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
                  <Box>
                    <div style={{fontWeight:'bold', fontSize:'1.5rem', textAlign:'center'}}>{item.n}</div>
                    <div>{item.n2}</div>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={10}>
                {item.hrList.map((hr,iH)=>(
                  <Button onClick={()=>funOnHourClick(index, iH)} key={hr.v} color='info' variant={hr.a===1?'contained':'outlined'} 
                    sx={{paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:0, marginRight:0.2, marginBottom:0.2}}
                  >
                    {hr.n}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function PageAgentSellerSchedule({go2SellerHome}) {
  const navigate = useNavigate();
  const [agSh, setAgSh] = useState({});
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  
  const _useProperty = useProperty();
  const [agentProfile, setAgentProfile] = useState({});
  const [monthList, setMonthList] = useState([]);
  const [monthName, setMonthName] = useState('-');
  const [dateList, setDateList] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [copyOpen, setCopyOpen] = React.useState(false);
  const copyClose = ()=>{
    setCopyOpen(false);
  }
  const [copyDate, setCopyDate] = React.useState({hrList:[]});
  const [agBusyHours, setAgBusyHours] = React.useState([]);
  const [isModified, setIsModified] = React.useState(false);

  useEffect(()=>{
    if(!_useProperty.property){
      navigate('/');
      return;
    }
    setIsLoading(false);
    setRecord(_useProperty.property);

    const fetchAgSh = async() =>{
      var _res = await AppHelper.api('property/item', 'POST', {uid:_useProperty.property.uid, code:'C', pid:_useProperty.property.pid});
      if(_res.status !== 1 || !_res.data || !_res.data.agSh){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Schedule!'});
        return;
      }
      setAgSh(_res.data);
      var _agSh = _res.data.agSh;
      bindDates(_agSh, []);
    };

    const fetchAgentProfile = async() =>{
      setIsLoading(true);
      var _res = await AppHelper.api('user/profile', 'POST', {uid:_useProperty.property.uid});
      setIsLoading(false);
      if(_res.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Profile!'});
        return;
      }
      setAgentProfile(_res.data);
      _useProperty.property.agProfile = _res.data;
      _useProperty.setProperty(_useProperty.property);
      fetchAgSh();
    };
    fetchAgentProfile();
  }, []);
  const bindDates = (_agSh, _busyHours, monthSetAuto=true)=>{
    var _dateS = dayjs(_agSh.ds);
    var _dateE = dayjs(_agSh.de);
    var _dateList = [];
    var _monthList = [];
    var _isMonthSet = false;
    var _slShDateList = (_useProperty.property && _useProperty.property.slSh && _useProperty.property.slSh.d) ? _useProperty.property.slSh.d : [];
    while (_dateS.isBefore(_dateE) || _dateS.isSame(_dateE)) {
      var _monthName = _dateS.format('MMM');
      if(monthSetAuto && !_isMonthSet){
        setMonthName(_monthName);
        _isMonthSet = true;
      }
      var _hrList = [];
      for (let hour = 7; hour <= 21; hour++) {
        _hrList.push({v:hour, n:dayjs().hour(hour).format('hA')});
      }
      var _wd = _dateS.format('ddd').toUpperCase();
      if(!_agSh.any && _agSh.avail && _agSh.avail.length>0){
        var _arrAvail = _agSh.avail.filter(ad=>ad.d==_wd);
        if(_arrAvail.length<1){
          _hrList = [];
        }
        else{
          _hrList = _hrList.filter(hh=> hh.v >= _arrAvail[0].hf && hh.v <= _arrAvail[0].ht);
        }
      }
      if(_hrList.length>0){
        _hrList.forEach(_hr=>{
          var _hrDT = dayjs(_dateS).add(_hr.v, 'hours');
          _hr.agBusy = false;
          _busyHours.forEach(bhr=>{
            var _start = dayjs(dayjs(bhr.start).format('YYYY-MM-DDTHH:mm'));
            var _end = dayjs(dayjs(bhr.end).format('YYYY-MM-DDTHH:mm'));
            if(_hrDT.isBetween(_start, _end, 'second', '[]'))
              _hr.agBusy = true;
          });
        });
        var _dateItem = {
          v:_dateS.format('YYYY-MM-DD'), a: 0,
          n:_dateS.format('D'), n2:_wd,
          dddd: _dateS.format('dddd').toUpperCase(),
          show: _monthName === monthName,
          hrList: [..._hrList],
          i: _dateList.length
        };
        var _arrSlsh = _slShDateList.filter(shd=>shd.v == _dateItem.v);
        if(_arrSlsh.length>0){
          _dateItem.hrList = [..._arrSlsh[0].hrList];
        }
        _dateList.push(_dateItem);
        var _m = _dateS.format('YYYY-MM');
        var _arr = _monthList.filter(r=>r.v === _m);
        if(_arr.length<1){
          _monthList.push({v:_m, n:_monthName});
        }
      }
      _dateS = _dateS.add(1, 'day');
    }
    setMonthList(_monthList);
    if(_monthList.length<1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to get the Month!'});
      return;
    }
    setDateList(_dateList);
  }
  const onCopy = (arg, argIndex)=>{
    if(!dateList || dateList.length<=argIndex){
      return;
    }
    var _date = dateList[argIndex];
    setCopyDate(_date);
    setCopyOpen(true);
  };
  const onDateClick = (arg, argIndex)=>{
    var _dateList = [...dateList];
    if(_dateList.length<=argIndex) return;
    var _date = _dateList[argIndex];
    _date.a = _dateList[argIndex].a === 0 ? 1 : 0;
    if(!_date.hrList) return;
    _date.hrList.forEach(h=>{
      h.a = _date.a;
    });
    setDateList(_dateList);
    saveSlSh(_dateList);
  }
  const saveSlSh= async(_dateList) =>{
    if(!_dateList || _dateList.length<1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: No data to save!'});
      return;
    }
    // var _data = [];
    // _dateList.forEach(d=>{
    //   if(d.hrList && )
    // });
    var _res = await AppHelper.api('property/slSh', 'POST', { sid: _useProperty.property.sid, pid:_useProperty.property.pid, slSh: {d: _dateList} });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Schedule is saved!'});
    setIsModified(true);
  }
  const onAllChecked = ()=>{
    var _checkedAll = !checkedAll;
    setCheckedAll(_checkedAll);
    var _dateList = [...dateList];
    _dateList.forEach(d=>{
      d.a = _checkedAll ? 1 : 0;
      d.hrList.forEach(h=>{
        h.a = d.a;
      });
    });
    setDateList(_dateList);
    saveSlSh(_dateList);
  }
  const onMonthSelect = (arg)=>{
    setMonthName(arg.n);
    handleClose();
  }
  const onHourClick = (iDate, iHr)=>{
    // var _dateList = [...dateList];
    // if(_dateList.length<=iDate) return;
    // var _date = _dateList[iDate];
    // if(!_date.hrList || !_date.hrList[iHr]) return;
    // _date.hrList[iHr].a = _date.hrList[iHr].a === 1? 0:1;
    // setDateList(_dateList);
    // saveSlSh(_dateList);
  }
  const copyToEveryday = ()=>{
    var _dateList = [...dateList];
    _dateList.forEach(d=>{
      if(d.v != copyDate.v){
        d.a = copyDate.a;
        d.hrList = [...copyDate.hrList];
      }
    });
    setDateList(_dateList);
    saveSlSh(_dateList);
    setCopyOpen(false);
  }
  const copyToAllWeekdays = ()=>{
    var _dateList = [...dateList];
    _dateList.forEach(d=>{
      if(d.v != copyDate.v && d.n2.toUpperCase() == copyDate.n2){
        d.a = copyDate.a;
        d.hrList = [...copyDate.hrList];
      }
    });
    setDateList(_dateList);
    saveSlSh(_dateList);
    setCopyOpen(false);
  }
  const onDone = async()=>{
    if(isModified){
      var _res = await AppHelper.api('seller/schedule/notify/agent', 'POST', {
        phoneNo: agentProfile.ph,
        uid: _useProperty.property.uid,
        ag_n: agentProfile.n,
        s_n: _useProperty.property.s,
        p_n: _useProperty.property.n,
        pid: _useProperty.property.pid,
      });
      setIsLoading(false);
      if(_res.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to notify Agent!'});
        return;
      }
      setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Notified to Agent!'});
    }
    go2SellerHome();
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {isLoading? (
        <AppLoading />
      ):(
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'80vh', xs:'75vh'}, backgroundColor:'rgba(0,0,0,0.02)', position:'relative' }} elevation={3}>
          <Box m={2} style={{color:'#777'}}>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Box display="flex" justifyContent="flex-start">
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant=""
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {monthName}
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MonthDdlItems items={monthList} funOnClick={onMonthSelect} />
                  </StyledMenu>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <DateListItems items={dateList} funOnCheckDate={onDateClick} funOnHourClick={onHourClick} funOnCopy={onCopy} />
                </Box>
              </Grid>
              {/* <Grid item xs={1}></Grid>
              <Grid item xs={11} mt={1}>
                <Button onClick={go2SellerHome} color="info" variant='contained' sx={{}} size="large">
                  HOME
                </Button>
              </Grid> */}
            </Grid>
          </Box>
            {/* <Button onClick={onDone} color="info" variant='contained' sx={{position:'fixed', bottom:16, right:16}} size="large">
              {isModified?'DONE':'BACK'}
            </Button> */}
            {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
              <BottomNavigation
                showLabels
              >
                {!isModified && <BottomNavigationAction onClick={go2SellerHome} label="BACK" icon={<KeyboardReturnIcon />} />}
                {isModified && <BottomNavigationAction onClick={onDone} label="DONE" icon={<AssignmentTurnedInIcon />} />}
              </BottomNavigation>
            </Paper> */}
        </Paper>
      )}
      <CopyPopup
        open={copyOpen}
        onClose={copyClose}
        copyDate={copyDate}
        copyToEveryday={copyToEveryday}
        copyToAllWeekdays={copyToAllWeekdays}
        daysTotal={dateList.length}
      />
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </Container>
  );
}
