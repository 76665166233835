import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar2 from './common/app-top-bar';
import AppLoading from './common/app-loading';
import '../css/property-list.css';
import { Container, Paper, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import PhoneNumber from 'libphonenumber-js';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import AssignmentIcon from '@mui/icons-material/Assignment';

function PropertyListItems({ items, funOnClick, calendarActive }) {
  return (
    <List>
      {items.map((item) => (
        <div key={item.id}>
          <ListItem key={item.id} disablePadding>
            <ListItemButton onClick={() => funOnClick(item)} sx={{minWidth:300}}>
              {/* <ListItemText primary={item.n} /> */}
              <ListItemText
                primary={item.n}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {/* Seller */}
                    </Typography>
                    <span style={{marginLeft:5}}>-</span>
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default function PageBuyerAdd() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { isAgent, bid } = useParams();
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState('SG'); // State to hold the selected value
  const [phoneNumber, setPhoneNumber] = useState('99998888');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('0');
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });

  const [loading, setLoading] = useState(false);
  const [txtTitle, setTxtTitle] = useState('');
  const [address, setAddress] = useState({country:'', lat:0, lng:0, address:''});
  const txtName = useRef(null),
    txtPhoneNo = useRef(null);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [txtPhoneNoOK, setTxtPhoneNoOK] = useState(true);
  const bookingEarliestList = [
    {v:-1, n: '-- Select --'}, {v:0, n:'0 day'}, {v:1, n:'1 day'}, {v:2, n:'2 days'}, {v:3, n:'3 days'}, {v:4, n:'4 days'}, {v:5, n:'5 days'},  {v:6, n:'6 days'}, {v:7, n:'7 days'}
  ];
  const [bookingEarliest, setBookingEarliest] = useState(0);
  const [bookingEarliestOK, setBookingEarliestOK] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
    // useEffect(()=>{
    //   var _arg = { accessToken: oUser.accessToken };
    //   AppHelper.api('user/calendar', 'POST', _arg).then(_resCalendar=>{
    //     console.log('res', _resCalendar);
    //   });
    //});
  //const { setProperty } = useProperty();
  const _useProperty = useProperty();

  useEffect(()=>{
    if(bid && bid.length>0){
      setIsEdit(true);
      if(!_useProperty || !_useProperty.property || !_useProperty.property.b){
        navigate('/home');
        return;
      }
      var _arr = _useProperty.property.b.filter(r=>r.bid == bid);
      if(_arr.length<1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Buyer Info!'});
        navigate('/home');
        return;
      }
      var _rec = _arr[0];
      console.log(_rec);
      txtName.current.value = _rec.n;
      if(_rec.ph && _rec.ph.length>1){
        const _phoneNoParsed = PhoneNumber(_rec.ph);
        if (_phoneNoParsed && _phoneNoParsed.isValid()) {
          txtPhoneNo.current.value = _phoneNoParsed.formatNational();
          setFormattedPhoneNumber(_phoneNoParsed.formatInternational());
        }
      }
      setBookingEarliest(_rec.ebd);
    }
    const fetchCountryList = async() =>{
      countryList.splice(0);
      var _resCountries = await AppHelper.api('countries', 'GET');
      var _countryList = [];
      for (const [code, name] of Object.entries(_resCountries)) {
        _countryList.push({code, name: `${name} (${code})`});
      }
      setCountryList(_countryList);
    };
    fetchCountryList();
  }, []);

  const handlePhoneNoChange = (event) => {
    const _phoneNo = event.target.value;
    if(_phoneNo.length>0){
      const _phoneNoParsed = PhoneNumber(_phoneNo, countryCode);
      if (_phoneNoParsed && _phoneNoParsed.isValid()) {
        const formattedNumber = _phoneNoParsed.formatInternational();
        setFormattedPhoneNumber(formattedNumber);
        setTxtPhoneNoOK(true);
      } else {
        setFormattedPhoneNumber(null);
        setTxtPhoneNoOK(false);
      }
      setPhoneNumber(_phoneNo);
    }
    else{
      setFormattedPhoneNumber('');
      setTxtPhoneNoOK(true);
    }
  };
  const handleCountrySelect = (event) => {
    setCountryCode(event.target.value);
  };
  const onAddCancel = ()=>{
    go2Detail();
  };
  const onAddBuyer = async()=>{
    var _validateOK = true;
    var _name = txtName.current.value;

    if((!_name || _name.length<1)){
      _validateOK =  false;
      setTxtNameOK(false);
    }
    else setTxtNameOK(true);
    if(formattedPhoneNumber!='' && !formattedPhoneNumber){
      _validateOK = false;
      setTxtPhoneNoOK(false);
    }
    else setTxtPhoneNoOK(true);
    if(bookingEarliest<0){
      setBookingEarliestOK(false);
      _validateOK = false;
    }
    if(!_validateOK) return;

    var _payload = {
      uid: oUser.uid, 
      pid: _useProperty.property.pid,
      n: _name,
      ph: formattedPhoneNumber,
      ag: isAgent==='1'?1:0,
      ebd: bookingEarliest
    };
    setLoading(true);
    var _url = 'property/buyer/add';
    if(isEdit){
      _url = 'property/buyer/update';
      _payload.bid = bid;
    }
    var _res = await AppHelper.api(_url, 'POST', _payload);
    setLoading(false);
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Buyer info added!'});
    if(!isEdit){
      if(!_useProperty.property.b) _useProperty.property.b = [];
      _useProperty.property.b.push(_res.data);
      navigate(`/buyer/added/${_res.data.bid}`);
    }
    else{
      navigate(`/property/detail/${_useProperty.property.pid}`);
    }
  }
  const onRemove = async()=>{
    if(!window.confirm('Are you sure you want to remove?')) return;
    var _arr = _useProperty.property.b.filter(r=>r.bid == bid);
    var _rec = _arr[0];
    var _payload = {
      uid: oUser.uid,
      pid: _useProperty.property.pid,
      bid: bid
    };
    var _res = await AppHelper.api('property/buyer/remove', 'POST', _payload);
    setLoading(false);
    console.log(_res);
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to remove!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Buyer info removed!'});
    go2Detail();
  }
  const go2Detail = ()=>{
    navigate(`/property/detail/${_useProperty.property.pid}`);
  }
  const bookingEarliestSelect = async(arg) =>{
    setBookingEarliest(arg.target.value);
    setBookingEarliestOK(bookingEarliest>0);
  };

  return (
    <div>
      <AppTopBar2 />
      {loading? (
        <AppLoading />
      ):(
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'80vh', xs:'75vh'} }} elevation={3}>
            
            <Box p={2} pl={2} pr={2} alignItems="flex-start">
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>{isEdit?'':'ADD'} BUYER {isAgent==='1'?'AGENT':''}</span>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                      </Typography>
                      {" — " + _useProperty.property.n}
                    </React.Fragment>
                  }
                />
                {/* <ListItemAvatar sx={{minWidth:70, display:'flex', justifyContent:'center'}}>
                  <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
                </ListItemAvatar> */}
              </ListItem>
              <Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1}>
                    <TextField inputRef={txtName} error={!txtNameOK} label="BUYER NAME" variant="outlined" fullWidth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1.5}>
                    <FormControl fullWidth>
                      <InputLabel id='lblDdlCountry'>Country</InputLabel>
                      <Select labelId='lblDdlCountry' label="Country"
                        value={countryCode}
                        onChange={handleCountrySelect}
                      >
                        {countryList.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box m={.5} pt={1}>
                    <TextField onChange={handlePhoneNoChange} inputRef={txtPhoneNo} error={!txtPhoneNoOK} id="txtPhoneNo" label="Phone Number" variant="outlined" fullWidth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1}>
                    <span style={{opacity:0.5, fontStyle:'italic'}}>{address.address}</span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1.5}>
                    <FormControl fullWidth error={!bookingEarliestOK}>
                      <InputLabel id='lblDdlBuffer'>EARLIEST BOOKING</InputLabel>
                      <Select labelId='lblDdlBuffer'
                        value={bookingEarliest}
                        onChange={bookingEarliestSelect}
                        label="EARLIEST BOOKING"
                      >
                        {bookingEarliestList.map((item) => (
                          <MenuItem key={item.v} value={item.v}>
                            {item.n}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container pt={.5}>
                  <Grid item xs={6}>
                    <Button onClick={onAddCancel} color="secondary">CANCEL</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button onClick={onAddBuyer} color="info" variant="outlined">{isEdit? 'UPDATE':'SAVE'}</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container pt={.5}>
                  <Grid item xs={6}>
                    {isEdit?(
                      <Button onClick={onRemove} color="warning">DELETE</Button>
                    ):(
                      <div></div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </div>
  );
}
