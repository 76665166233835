import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import SpaCheck from './common/spa-check';
import '../css/property-list.css';
import { Avatar, Container, Paper, Button, Divider, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid 
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';

function PropertyListItems({ items, funOnClick }) {
  return (
    <List>
      {items.map((item, index) => (
        <div key={item.id}>
          <ListItem key={item.id} disablePadding>
            <ListItemButton onClick={() => funOnClick(item)} sx={{minWidth:300}}>
              {/* <ListItemText primary={item.n} /> */}
              <ListItemText
                primary={item.n}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      SELLER —
                    </Typography>
                    <span style={{marginLeft:5}}>{item.detail.s}</span><br/>
                    <span style={{fontStyle:'italic', opacity:0.65}}>{item.sStatus}</span>
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default function PageDev() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [phoneNumber, setPhoneNumber] = useState('99998888');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('0');
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isEdit, setIsEdit] = useState(true);
  const [readingData, setReadingData] = useState(true);
  const [records, setRecords] = useState([]);

  const [address, setAddress] = useState({country:'', lat:0, lng:0, address:''});
  const txtName = useRef(null),
    txtSeller = useRef(null),
    txtPostalCode = useRef(null);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const feedbackTypes = [
    {code:'-', name: '-- Select --'},
    {code: 'BUG', name:'BUG REPORT'},
    {code: 'FEATURE', name:'FEATURE REQUEST'},
    {code: 'GENERAL', name:'GENERAL FEEDBACK'},
  ];
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('-');
  const [feedbackTypeOK, setFeedbackTypeOK] = useState(true);
  var appVer = '';
  var appVerCheckId;

  const versionCheck = async ()=>{
    var _res = await AppHelper.api('api/version');
    if(_res && _res.status===1 && _res.data){
      if(appVer === '' || appVer === _res.data.v){
        appVer = _res.data.v;
        console.log('same-v:', appVer);
      }
      else{
        appVer = _res.data.v;
        console.log('v-changed:', appVer);
        if(window && 'caches' in window){
          caches.keys().then((names) => {
          names.forEach(name => {
              caches.delete(name);
          })
          })
        }
        window.location.reload(true);
      }
    }
    if(appVerCheckId) clearTimeout(appVerCheckId);
    appVerCheckId = setTimeout(() => {
      versionCheck();
    }, 1000*10);
  };
  versionCheck();

  useEffect(()=>{
    // if(!property){
    //   navigate('/');
    //   return;
    // }
    // txtName.current.value = property.n;
    // txtSeller.current.value = property.s;
    // if(property.sp && property.sp.length>1){
    //   const _phoneNoParsed = PhoneNumber(property.sp);
    //   if (_phoneNoParsed && _phoneNoParsed.isValid()) {
    //     txtPhoneNo.current.value = _phoneNoParsed.formatNational();
    //   }
    // }
    // txtPostalCode.current.value = property.pc;
    // if(property.address)
    //   setAddress(property.address);
  }, []);
  const onSelectDdl = (event) => {
    var _newValue = event.target.value;
    setFeedbackType(_newValue);
    console.log(_newValue);
    if(_newValue !== '-'){
      setFeedbackTypeOK(false);
    }
    else setFeedbackTypeOK(true);
    setTimeout(() => {
      console.log(feedbackTypeOK);
    }, 500);
  };
  const onAddSubmit = async () => {
    if(!isDataOK()) return;
    // var _data = {
    //   uid: oUser.uid,
    //   n: txtName.current.value,
    //   s: txtSeller.current.value,
    //   sp: formattedPhoneNumber,
    //   pc: txtPostalCode.current.value,
    //   address: address
    // }
    // setProperty(_data);
    // navigate('/agent/schedule');
    
    //setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Feedback submitted!'});
  };
  const onUpdate = async()=>{
    if(!isDataOK()) return;
    // var _data = {
    //   uid: oUser.uid,
    //   pid: property.pid,
    //   n: txtName.current.value,
    //   s: txtSeller.current.value,
    //   sp: formattedPhoneNumber,
    //   pc: txtPostalCode.current.value,
    //   address: address
    // }
    setIsSubmitting(true);
    var _res = await AppHelper.api('api/email', 'POST', { email:oUser.email, name:oUser.displayName, feedbackType, feedbackMsg:feedback });
    setIsSubmitting(false);
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Submission failed!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Feedback submitted!'});
    //navigate('/property/detail/' + property.pid);
  }
  const onAddCancel = ()=>{
    window.history.back();
  };
  const isDataOK = ()=>{
    var _validateOK = true;
    if((!feedback || feedback.length<1)){
      _validateOK =  false;
      setTxtNameOK(false);
      setMsgBox({...msgBox, open: true, msg: 'Please enter the feedback message!'});
    }
    else setTxtNameOK(true);
    if(!feedbackType || feedbackType.length<1 || feedbackType === '-'){
      setFeedbackTypeOK(false);
      _validateOK = false;
    }
    else setFeedbackTypeOK(true);

    return _validateOK;
  };
  const onChangeFeedback = (arg)=>{
    setFeedback(arg.target.value);
  };

  return (
    <>
      <AppTopBar />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'} }} elevation={3}>
          <Box p={2} pl={2} pr={2} alignItems="flex-start">
            <SpaCheck></SpaCheck>
            {/* <ListItem disablePadding>
              <ListItemText
                primary={
                  <React.Fragment>
                    <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>
                      {isEdit? 'DEV':'NEW LISTING'}
                    </span>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                    </Typography>
                    {" — " + oUser.displayName}
                  </React.Fragment>
                }
              />
              <ListItemAvatar sx={{minWidth:70, display:'flex', justifyContent:'center'}}>
                <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
              </ListItemAvatar>
            </ListItem> */}
            <Grid>
              {/* <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1.5}>
                  <FormControl fullWidth error={!feedbackTypeOK}>
                    <InputLabel id='lblDdlCountry'>FEEDBACK TYPE</InputLabel>
                    <Select labelId='lblDdlCountry' label="FEEDBACK TYPE"
                      value={feedbackType}
                      onChange={onSelectDdl}
                    >
                      {feedbackTypes.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1}>
                  {/* <textarea onChange={onChangeFeedback} rows={7} style={{width:'100%', textAlign:'left', border: '1px solid #d3d3d3'}} ></textarea> */}
                </Box>
              </Grid>
              <Grid container pt={.5}>
                <Grid item xs={6}>
                  <Button onClick={onAddCancel} color="secondary">BACK</Button>
                </Grid>
                <Grid item xs={6}>
                  {/* <Box display="flex" justifyContent="flex-end">
                    { isEdit && 
                      <LoadingButton
                        onClick={onUpdate}
                        loading={isSubmitting}
                        variant="contained"
                      >
                        <span>SUBMIT</span>
                      </LoadingButton>
                    }
                  </Box> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </>
  );
}
