//import { register } from 'service-worker.js';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './UserContext';
import { PropertyProvider } from './contexts/context-property';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import './index.css';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import Login from './components/login';
import PageAdd2HomeScreen from './components/add2-home-screen';
import PageBuyerAdd from './components/buyer-add';
import PageBuyerAdded from './components/buyer-added';
import PageDev from './components/dev';
import PageFeedback from './components/feedback';
import PageHome from './components/home';
import PageLand from './components/land';
import PageMsgLog from './components/msg-log';
import PagePhone from './components/phone';
import PageProfile from './components/profile';
import PageProperty from './components/property';
import PagePropertyList from './components/property-list';
import PagePropertyDetail from './components/property-detail';
import PagePropertyCreated from './components/property-created';
import PageSeller from './components/seller';
import AgentSchedule from './components/agent-schedule';
import CalendarList from './components/calendar-list';
import reportWebVitals from './reportWebVitals';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Router>
    {/* <GoogleOAuthProvider clientId="114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com"
      redirect_uri="https://accounts.google.com/o/oauth2/v2/auth?client_id=114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com&redirect_uri=http://localhost:3000&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/calendar.calendars.readonly%20https://www.googleapis.com/auth/calendar.events.owned&access_type=offline"
    > */}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserProvider>
        <PropertyProvider>
          <Routes>
              <Route path="/" element={<App />} />
              <Route path="/add2HomeScreen" element={<PageAdd2HomeScreen />} />
              <Route path="/agent/schedule" element={<AgentSchedule />} />
              <Route path="/buyer/add" element={<PageBuyerAdd />} />
              <Route path="/buyer/added/:bid" element={<PageBuyerAdded />} />
              <Route path="/buyer/add/:isAgent" element={<PageBuyerAdd />} />
              <Route path="/buyer/add/:isAgent/:bid" element={<PageBuyerAdd />} />
              <Route path="/calendar/list" element={<CalendarList />} />
              <Route path="/dev" element={<PageDev />} />
              <Route path="/feedback" element={<PageFeedback />} />
              <Route path="/home" element={<PageHome />} />
              <Route path="/land/:code/:id" element={< PageLand />} />
              <Route path="/land/:code/:id/:id2/:id3" element={< PageLand />} />
              <Route path="/msg" element={<PageMsgLog />} />
              <Route path="/login" element={< App />} />
              <Route path="/phone" element={<PagePhone />} />
              <Route path="/profile" element={<PageProfile />} />
              <Route path="/property/:pid" element={<PageProperty />} />
              <Route path="/property/list" element={<PagePropertyList />} />
              <Route path="/property/created" element={<PagePropertyCreated />} />
              <Route path="/property/detail/:pid" element={<PagePropertyDetail />} />
              <Route path="/seller" element={<PageSeller />} />
              {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/" exact component={App} />
            <Route path="/login" component={Login} /> */}
          </Routes>
        </PropertyProvider>
      </UserProvider>
    </LocalizationProvider>
    {/* </GoogleOAuthProvider> */}
  </Router>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register({
  enabled: //environment.production && 
    (navigator['standalone'] || window.matchMedia('(display-mode: standalone)').matches),
});
reportWebVitals();
