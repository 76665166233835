import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { styled, alpha } from '@mui/material/styles';
import { Box, Container, Paper, Button, Grid, Dialog, DialogTitle, Chip, Snackbar, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AppLoading from './common/app-loading';
import { AppHelper } from '../AppHelper';
import Fab from '@mui/material/Fab';
import { useProperty } from '../contexts/context-property';

function CopyPopup(props){
  const { onClose, open, bkDate, bkHr, onBookCancel } = props;
  const handleClose = () =>{ onClose() };
  const txtReason = useRef(null);
  const [txtReasonOK, setTxtReasonOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onCancel = async()=>{
    var _reason = txtReason.current.value;
    if((!_reason || _reason.length<1)){
      setTxtReasonOK(false);
      return;
    }
    else setTxtReasonOK(true);

    setIsSubmitting(true);
    await onBookCancel(_reason);
    setIsSubmitting(false);
  }

  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>CANCEL VIEWING</DialogTitle>
      <Box display='flex' flexDirection='column' alignItems='center' sx={{textTransform:'uppercase'}}>
        <span style={{fontWeight:'bold', fontSize:'1.05rem'}}>ON {bkDate}</span>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='center' mt={1} mb={1}>
          <Chip color="success" label={bkHr} />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box m={.5} pt={1} p={2} pb={0.5}>
            <TextField inputRef={txtReason} error={!txtReasonOK} label="Reason to cancel (Visible only to Agent)" variant="outlined" fullWidth />
          </Box>
        </Grid>
        <Grid item xs={12} p={2} pt={1} pb={0.5}>
          {/* <Button color='info' onClick={onCancel} variant="contained" sx={{width:'100%'}}>CANCEL & NOTIFY AGENT</Button> */}
          <LoadingButton sx={{width:'100%'}}
            onClick={()=>{onCancel()}}
            loading={isSubmitting}
            variant="contained"
          >
            <span>BOOK & NOTIFY AGENT</span>
          </LoadingButton>
        </Grid>
        <Grid item xs={12} p={2} pt={0} pb={0.5}>
          {!isSubmitting &&
          <Button onClick={handleClose} sx={{width:'100%'}}>BACK</Button>}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default function PageBuyer({go2Next}) {
  const navigate = useNavigate();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [oBuyer, setBuyer] = useState({});
  
  const {property, setProperty} = useProperty();
  const [agentProfile, setAgentProfile] = useState({});
  const [bkDate, setBkDate] = useState('');
  const [bkHr, setBkHr] = useState('');
  const [popOpen, setPopOpen] = React.useState(false);
  const [refreshCount, setRefreshCount] = useState(1);

  useEffect(()=>{
    if(!property){
      navigate('/');
      return;
    }
    setIsLoading(false);

    if(!property.bid){
      navigate('/');
      return;
    }
    var _bid = property.bid;
    if(!property.b || !property.b.find(r=>r.bid === _bid)){
      navigate('/');
      return;
    }
    var _buyer = property.b.find(r=>r.bid === _bid);
    setBuyer(_buyer);
    property.bProfile = _buyer;
    setProperty(property);
    
    if(_buyer.bkDate){
      setIsNew(false);
      var _bkDate = dayjs(_buyer.bkDate).add(_buyer.bkHr, 'hours').add(_buyer.bkM, 'minutes');
      setBkDate(_bkDate.format('DD MMM (dddd)'));
      setBkHr(_bkDate.format('hh:mm A'));
    }
    else setIsNew(true);

    const fetchAgentProfile = async() =>{
      setIsLoading(true);
      var _res = await AppHelper.api('user/profile', 'POST', {uid:property.uid});
      setIsLoading(false);
      if(_res.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Profile!'});
        return;
      }
      setAgentProfile(_res.data);
      property.agProfile = _res.data;
      setProperty(property);
      fetchSellerInfo();
    };
    fetchAgentProfile();

    const fetchSellerInfo = async()=>{
      setIsLoading(true);
      var _res = await AppHelper.api('property/item', 'POST', {uid:property.sid, code:'S', pid:property.pid});
      setIsLoading(false);
      if(_res.status !== 1 || !_res.data){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Seller Schedule!'});
        return;
      }
      if(!_res.data.slSh){
        setIsReady(false);
      }
      else{
        setIsReady(true);
        property.slSh = _res.data.slSh;
        if(_res.data.slSh && _res.data.slSh.d && _res.data.slSh.d.length>0){
          var _dates = _res.data.slSh.d;
          var _de = dayjs(_dates[_dates.length-1].v);
          if(_de.isBefore(dayjs().format('YYYY-MM-DD'))){
            setIsNew(true);
            return;
          }
        }
        setProperty(property);
      }
    };
  }, [refreshCount]);

  const onGo2Next = ()=>{
    go2Next();
  };
  const onBookCancel = async(reason)=>{
    var _res = await AppHelper.api('property/buyer/book/cancel', 'POST', {uid:property.uid, pid:property.pid, bid:oBuyer.bid, reason});
    if(_res.status !== 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to cancel the viewing. Please refresh and try again!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Viewing is cancelled!'});
    setPopOpen(false);
    setIsNew(true);
    //setRefreshCount(refreshCount+1);
    
    var _buyer = property.b.find(r=>r.bid === oBuyer.bid);
    if(_buyer)
      _buyer.bkDate = null;
    setProperty(property);
  }
  const onCancel = ()=>{
    setPopOpen(true);
  }
  const popClose = ()=>{
    setPopOpen(false);
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {isLoading? (
        <AppLoading />
      ):(
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'70vh'} }} elevation={3}>
          <Box style={{color:'#777'}}>
            <Grid>
              <Grid item>
                Hi, <span style={{color:'#666', fontWeight:'bold'}}>{oBuyer.n}</span>
              </Grid>
              {
                isReady && isNew &&
                <Box mt={2}>
                  Please book a viewing slot for the property at <span style={{color:'#000', fontWeight:'bold'}}>{property.n}</span> with <span style={{color:'#333', fontWeight:'bold'}}>{agentProfile?agentProfile.n:'-'}</span>.
                </Box>
              }
              {
                isReady && !isNew &&
                <Box mt={2}>
                  You have a viewing at <span style={{color:'#000', fontWeight:'bold'}}>{property.n}</span> booked for
                  <div>
                    <span style={{color:'#000', fontWeight:'bold'}}> {bkDate}</span> at <span style={{color:'#000', fontWeight:'bold'}}> {bkHr}</span> with <span style={{color:'#333', fontWeight:'bold'}}>{agentProfile?agentProfile.n:'-'}</span>.
                  </div>
                </Box>
              }
              <Grid item mt={3} mb={7}>
                {
                  isReady?(
                    isNew?(
                      <Box display='flex' pt={3} width='100%' alignItems='center' justifyContent='center'>
                        <Fab onClick={onGo2Next} sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
                          <div>
                            <div style={{fontSize:'1.5rem'}}>NEXT</div>
                          </div>
                        </Fab>
                      </Box>
                    ):(
                      <Box display='flex' flexDirection='column' mt={5} p={5}>
                        <Button color="info" onClick={onCancel} variant="contained" sx={{marginBottom:1}} size="large">CANCEL</Button>
                        <Button color="info" onClick={onGo2Next} variant="contained" size="large">RESCHEDULE</Button>
                      </Box>
                    )
                  ):(
                    <div>Sorry! Seller schedule is still not confirmed yet for booking.
                      <div>
                        Please come back again later!
                      </div>
                    </div>
                  )
                }
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
      <CopyPopup
        open={popOpen}
        onClose={popClose}
        bkDate={bkDate}
        bkHr={bkHr}
        onBookCancel={onBookCancel}
      />
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </Container>
  );
}
