import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Dialog, DialogTitle, Button, Box, Menu, MenuItem, Grid, Chip,
  BottomNavigation, BottomNavigationAction, Snackbar, TextField
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppHelper } from '../AppHelper';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AppLoading from './common/app-loading';
import { useProperty } from '../contexts/context-property';

function CopyPopup(props){
  const { onClose, open, copyDate, onBook, isReschedule } = props;
  const txtReason = useRef(null);
  const [txtReasonOK, setTxtReasonOK] = useState(true);
  const handleClose = () =>{ onClose() };
  //const [isReschedule, setIsReschedule] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // useEffect(()=>{
  //   console.log('effect - popup', isReschedule);
  // });
  const onBookSubmit = async()=>{
    if(isReschedule){
      var _reason = txtReason.current.value;
      if((!_reason || _reason.length<1)){
        setTxtReasonOK(false);
        return;
      }
      else setTxtReasonOK(true);
    }
    setIsSubmitting(true);
    await onBook(_reason);
    setIsSubmitting(false);
  };

  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>BOOK VIEWING</DialogTitle>
      <Grid container>
        <Grid item xs={12}>
          <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <span style={{fontWeight:'bold', fontSize:'1.5rem'}}>{copyDate.n}</span>
              <div>{copyDate.n2}</div>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} p={5} pt={2} pb={0}>
          {copyDate.hrList.map((hr,iH)=>(
            copyDate.hrBook === iH &&
            <Box key={hr.v} display='flex' flexDirection='column' alignItems='center' mb={1}>
              <Chip color="success" label={hr.n} />
            </Box>
            // <Button key={hr.v} color="success" variant={hr.a===1?'contained':'outlined'} fullWidth 
            //   sx={{}}
            // >
            //   {hr.n}
            // </Button>
          ))}
        </Grid>
        { isReschedule &&
        <Grid item xs={12}>
          <Box m={.5} pt={0} p={2} pb={0.5}>
            <TextField inputRef={txtReason} error={!txtReasonOK} label="Reason to reschedule (Visible only to Agent)" variant="outlined" fullWidth />
          </Box>
        </Grid>}
        {/* <Grid item xs={12} p={5} pt={3} pb={5}>
          <Button variant="contained" color="success" sx={{width:'100%'}}>1 PM</Button>
        </Grid> */}
        <Grid item xs={12} p={2} pt={4} pb={0.5}>
          {/* <Button onClick={()=>{onBook()}} variant="contained" sx={{width:'100%'}}>BOOK & NOTIFY AGENT</Button> */}
          <LoadingButton sx={{width:'100%'}}
            onClick={()=>{onBookSubmit()}}
            // endIcon={<SendIcon />}
            loading={isSubmitting}
            //loadingPosition="start"
            variant="contained"
          >
            <span>BOOK & NOTIFY AGENT</span>
          </LoadingButton>
        </Grid>
        <Grid item xs={12} p={2} pt={0} pb={0.5}>
          {!isSubmitting &&
          <Button onClick={handleClose} sx={{width:'100%'}}>CANCEL</Button>}
        </Grid>
      </Grid>
    </Dialog>
  );
}
CopyPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  copyDate: PropTypes.object.isRequired
};
function MonthDdlItems({items, funOnClick}){
  return(
    <div>
      {items.map((item, index) => (
        <MenuItem key={item.v} onClick={()=>funOnClick(item)} disableRipple>
          {item.n}
        </MenuItem>
      ))}
    </div>
  );
}
function DateListItems({ items, funOnCheckDate, funOnHourClick, funOnCopy, monthName }) {
  return (
    <Box>
      {items.map((item, index) => (
        item.month === monthName &&
        <Grid key={item.v + '_' + item.vm} container>
          <Grid item xs={12}>
            <Grid container sx={{border:'1px solid #efefef', backgroundColor:'#fff', borderRadius:'8px'}} mb={0.3} p={1}>
              <Grid item xs={2}>
                <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
                  <Box>
                    <div style={{fontWeight:'bold', fontSize:'1.5rem', textAlign:'center'}}>{item.n}</div>
                    <div>{item.n2}</div>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  {item.hrList.map((hr,iH)=>(
                    <Grid key={hr.v+ '_' + hr.vm} item xs={hr.sih} pr={0.2}>
                      {hr.a===1 && !hr.isBooked && !hr.agBusy && 
                      <Button onClick={()=>funOnHourClick(index, iH)} key={hr.v} color="info" variant={hr.agBusy?'contained':'outlined'} fullWidth
                        sx={{paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:0, marginRight:0.2, marginBottom:0.2, fontSize:'0.75rem'}}
                        disabled={hr.agBusy}
                      >
                        {hr.n}
                      </Button>}
                      {hr.a===1 && hr.isBooked && 
                      // <Button onClick={()=>funOnHourClick(index, iH)} key={hr.v} color="info" variant={hr.agBusy?'contained':'outlined'} fullWidth
                      //   sx={{paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:0, marginRight:0.2, marginBottom:0.2}}
                      //   disabled={hr.agBusy}
                      // >
                      //   {hr.n}
                      // </Button>
                      <Box display='flex' alignItems='center' justifyContent='flex-start' mt={0.1} mb={0.3} >
                        <Chip color="success" label={hr.n} sx={{width:'100%', fontSize:'0.9rem', height:'25px'}} />
                    </Box>
                      }
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function PageBuyerSchedule({go2BuyerHome}) {
  const navigate = useNavigate();
  const [agSh, setAgSh] = useState({});
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState({});
  const { property, setProperty } = useProperty();
  const [monthList, setMonthList] = useState([]);
  const [monthName, setMonthName] = useState('-');
  const [dateList, setDateList] = useState([]);
  const [refreshCount, setRefreshCount] = useState(1);
  const [isReschedule, setIsReschedule] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [copyOpen, setCopyOpen] = React.useState(false);
  const copyClose = ()=>{
    setCopyOpen(false);
  }
  const [copyDate, setCopyDate] = React.useState({hrList:[]});
  const [agBusyHours, setAgBusyHours] = React.useState([]);
  const [isModified, setIsModified] = React.useState(false);
  useEffect(()=>{
    if(!property){
      navigate('/');
      return;
    }
    setIsLoading(false);
    setRecord(property);

    const fetchAgSh = async() =>{
      setIsLoading(true);
      var _res = await AppHelper.api('property/item', 'POST', {uid:property.uid, code:'C', pid:property.pid});
      if(_res.status !== 1 || !_res.data || !_res.data.agSh){
        setIsLoading(false);
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Schedule!'});
        return;
      }
      setAgSh(_res.data);
      var _agSh = _res.data.agSh;
      var timeMin = _agSh.ds + 'T00:00:00Z';
      var timeMax = _agSh.de + 'T23:59:59Z';
      var _res = await AppHelper.api('user/calendar/freeBusy', 'POST', {uid:property.uid, timeMin, timeMax});
      setIsLoading(false);
      if(_res.status !== 1 || !_res.data || !_res.data.calendars){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Calendar!'});
        return;
      }
      var _calendars = _res.data.calendars;
      var _calendarId = _res.data.calendarId;
      if(!_calendars[_calendarId]){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Calendar!'});
        return;
      }
      var _busyHours = [];
      if(_calendars[_calendarId].busy){
        _busyHours = _calendars[_calendarId].busy;
      }
      setAgBusyHours(_calendars[_calendarId].busy);
      bindDates(_agSh, _busyHours);
    };
    fetchAgSh();
  }, [refreshCount]);
  const bindDates = (_agSh, _busyHours, monthSetAuto=true)=>{
    var _slotInHr = 60/_agSh.tShow;
    var _sih = 12/_slotInHr;
    _sih = _sih < 3 ? 3 : _sih;
    var _dateS = dayjs(_agSh.ds);
    var _dateE = dayjs(_agSh.de);
    var _dateList = [];
    var _monthList = [];
    var _isMonthSet = false;
    var _slShDateList = (property && property.slSh && property.slSh.d) ? property.slSh.d : [];
    var _today = dayjs().startOf('day');
    var _ebd = 0;
    if(property.bProfile && property.bProfile.ebd)
      _today = dayjs().add(property.bProfile.ebd, 'days').startOf('day');
      //_ebd = property.bProfile.ebd;

    while (_dateS.isBefore(_dateE) || _dateS.isSame(_dateE)) {
      if(!_dateS.isBefore(_today)){
       
        var _monthName = _dateS.format('MMM');
        if(monthSetAuto && !_isMonthSet){
          setMonthName(_monthName);
          _isMonthSet = true;
        }
        var _hrList = [];
        for (let hour = 7; hour <= 21; hour++) {
          var _today = dayjs().startOf('day');
          var _iSlot = 0;
          var _vm = 0;
          _hrList.push({v:hour, vm:0, n:_today.hour(hour).format('hh:mmA'), sih:_sih});
          while(_iSlot<_slotInHr-1){
            _iSlot++;
            _vm += _agSh.tShow;
            _hrList.push({v:hour, vm:_vm, n:_today.add(_vm, 'minutes').hour(hour).format('hh:mmA'), sih:_sih});
          }
        }
        var _wd = _dateS.format('ddd').toUpperCase();
        if(!_agSh.any && _agSh.avail && _agSh.avail.length>0){
          var _arrAvail = _agSh.avail.filter(ad=>ad.d==_wd);
          if(_arrAvail.length<1){
            _hrList = [];
          }
          else{
            _hrList = _hrList.filter(hh=> hh.v >= _arrAvail[0].hf && hh.v < _arrAvail[0].ht);
          }
        }
        if(_hrList.length>0){
          _hrList.forEach(_hr=>{
            var _hrDT = dayjs(_dateS).add(_hr.v, 'hours').add(_hr.vm, 'minutes');
            var _hrDTE = dayjs(_dateS).add(_hr.v, 'hours').add(_hr.vm, 'minutes').add(_agSh.tShow, 'minutes');//.add(-1, 'seconds');
            //_hr.agBusy = false;
            _busyHours.forEach(bhr=>{
              var _start = dayjs(dayjs(bhr.start).format('YYYY-MM-DDTHH:mm'));
              var _end = dayjs(dayjs(bhr.end).format('YYYY-MM-DDTHH:mm'));
              if(_hrDT.isBetween(_start, _end, 'second', '[)') || _hrDTE.isBetween(_start, _end, 'second', '()')){
                //console.log(_hr.vm, _hrDT.format('HHmmss'), _start.format('HHmmss'), _end.format('HHmmss'));
                _hr.agBusy = true;
                //console.log('####### BUSY ######', _hrDT.format('DD HHmmss'));
              }
              // if(_dateS.format('DD')==='20' && _start.format('DD')==='20'){
              //   ///console.log(_hrDT.format('YYYYMMDD HHmmss'), _start.format('YYYYMMDD HHmmss'));
              // }
            });
          });
          var _dateItem = {
            v:_dateS.format('YYYY-MM-DD'), a: 0,
            n:_dateS.format('D'), n2:_wd,
            show: _monthName === monthName,
            hrList: [..._hrList],
            i: _dateList.length,
            month:_dateS.format('MMM')
          };
          if(property.bProfile.bkDate && _dateItem.v === property.bProfile.bkDate){
            var _bkItem = _dateItem.hrList.find(rh=>rh.v == property.bProfile.bkHr && rh.vm == property.bProfile.bkM);
            if(_bkItem){
              _bkItem.isBooked = true;
              _dateItem.isBooked = true;
              _dateItem.hrList.filter(rh=>rh.v==property.bProfile.bkHr).isBooked = true;
            }
          }
          var _slSh = _slShDateList.find(shd=>shd.v == _dateItem.v);
          if(_slSh){
            //_dateItem.hrList = [..._slSh.hrList];
            _dateItem.hrList = _dateItem.hrList.filter(__h => _slSh.hrList.find(__h2=> __h2.a===1 && __h2.v === __h.v));
            _slSh.hrList.forEach(slHr=>{
              _dateItem.hrList.filter(rh=>rh.v==slHr.v).map(rr=>rr.a=1);
            });
          }
          _dateList.push(_dateItem);
          var _m = _dateS.format('YYYY-MM');
          var _arr = _monthList.filter(r=>r.v === _m);
          if(_arr.length<1){
            _monthList.push({v:_m, n:_monthName});
          }
        }
      }
      _dateS = _dateS.add(1, 'day'); 
    }
    setMonthList(_monthList);
    if(_monthList.length<1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to get the Month!'});
      return;
    }
    setDateList(_dateList);
  }
  const onHourClick = (iDate, iHr)=>{
    if(!dateList || dateList.length<=iDate){
      return;
    }
    var _date = dateList[iDate];
    _date.hrBook = iHr;
    _date.bkHr = _date.hrList[iHr].v;
    _date.bkM = _date.hrList[iHr].vm;
    setCopyDate(_date);
    if(property.bProfile && property.bProfile.bkDate && property.bProfile.bkDate.length>0){
      setIsReschedule(true);
    }
    setCopyOpen(true);
  }
  const onCopy = (arg, argIndex)=>{
    if(!dateList || dateList.length<=argIndex){
      return;
    }
    var _date = dateList[argIndex];
    setCopyDate(_date);
    setCopyOpen(true);
  };
  const onDateClick = (arg, argIndex)=>{
    var _dateList = [...dateList];
    if(_dateList.length<=argIndex) return;
    var _date = _dateList[argIndex];
    _date.a = _dateList[argIndex].a === 0 ? 1 : 0;
    if(!_date.hrList) return;
    _date.hrList.forEach(h=>{
      h.a = _date.a;
    });
    setDateList(_dateList);
    saveSlSh(_dateList);
  }
  const saveSlSh= async(_dateList) =>{
    if(!_dateList || _dateList.length<1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: No data to save!'});
      return;
    }
    // var _data = [];
    // _dateList.forEach(d=>{
    //   if(d.hrList && )
    // });
    var _res = await AppHelper.api('property/slSh', 'POST', { sid: property.sid, pid:property.pid, slSh: {d: _dateList} });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Schedule is saved!'});
    setIsModified(true);
  }
  const onMonthSelect = (arg)=>{
    setMonthName(arg.n);
    handleClose();
  }
  const onBook2 = async()=>{
    var _dateList = [...dateList];
    _dateList.forEach(dItem=>{
      dItem.hrList.map(hItem=>hItem.isBooked = false);
      if(dItem.v === copyDate.v){
        dItem.hrList.filter(hItem=>hItem.v===copyDate.bkHr).map(hItem=>hItem.isBooked = true);
      }
    });
  }
  const onBook = async(argReason='-')=>{
    var _ppt = property;
    var _bkTimeStart = dayjs(`${copyDate.v}T${copyDate.bkHr}:00:00`).add(copyDate.bkM, 'minutes');
    var _bkTimeEnd = _bkTimeStart.add(agSh.agSh.tShow, 'minutes');
    var _bProfile = _ppt.bProfile;
    var _res = await AppHelper.api('property/buyer/book', 'POST', {
      uid: _ppt.uid,
      calendarId: _ppt.agProfile.calendarId,
      en: '[DingDong] Viewing @ ' + _ppt.n,
      eloc: (_ppt.address && _ppt.address.address) ? _ppt.address.address : _ppt.n,
      desc: `Info:\nSeller: ${_ppt.s} (${_ppt.sp})\nBuyer: ${_bProfile.n} (${_bProfile.ph})`,
      pid: _ppt.pid, 
      bid: _ppt.bid, 
      bkDate: copyDate.v,
      bkHr: copyDate.bkHr,
      bkM: copyDate.bkM,
      bkTimeS: _bkTimeStart.format('YYYY-MM-DDTHH:mm:00'),
      bkTimeE: _bkTimeEnd.format('YYYY-MM-DDTHH:mm:00'),
      rs: argReason
    });
    
    if(_res.status != 1){
      if(_res.msg === 'CONFLICT'){
        setMsgBox({...msgBox, open: true, msg: 'Sorry! The selected slot seems already booked. Please try to book any other available slot.'});
        setCopyOpen(false);
        setProperty(_ppt);
        setRefreshCount(refreshCount+1);
        return;
      }
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Booking submitted!'});
    _ppt.bkDate = copyDate.v;
    _ppt.bkHr = copyDate.bkHr;
    setProperty(_ppt);
    setCopyOpen(false);

    var _bkDateT = dayjs(copyDate.v).format('D MMM \'YY');
    var _bkTimeStartT = _bkTimeStart.format('hh:mmA');
    var _bkTimeEndT = _bkTimeEnd.format('hh:mmA');
    var _urlNotifyAgent = 'buyer/book/notify/agent';
    var _dataNotifyAgent = { 
      pid: _ppt.pid,
      uid: _ppt.uid,
      phoneNo: _ppt.agProfile.ph,
      ag_n: _ppt.agProfile.n, b_n: _ppt.bProfile.n, s_n: _ppt.s, p_n: _ppt.n,
      bkDate: _bkDateT,
      bkTimeStart: _bkTimeStartT,
      bkTimeEnd: _bkTimeEndT
    };
    var _oldBooking;
    if(_ppt.bProfile.bkDate){
      var _oldTimeStart = dayjs(`${_ppt.bProfile.bkDate}T${_ppt.bProfile.bkHr}:00:00`).add(_ppt.bProfile.bkM, 'minutes');
      var _oldTimeEnd = _oldTimeStart.add(agSh.agSh.tShow, 'minutes');
      _oldBooking = {
        oldDate: _oldTimeStart.format('D MMM \'YY'),
        oldTimeStart: _oldTimeStart.format('hh:mmA'),
        oldTimeEnd: _oldTimeEnd.format('hh:mmA'),
        reason: '-'
      };
    }
    if(_oldBooking){
      _urlNotifyAgent = 'buyer/edit/notify/agent';
      _dataNotifyAgent.oldDate = _oldBooking.oldDate;
      _dataNotifyAgent.oldTimeStart = _oldBooking.oldTimeStart;
      _dataNotifyAgent.oldTimeEnd = _oldBooking.oldTimeEnd;
      _dataNotifyAgent.reason = argReason;
    }
    var _res = await AppHelper.api(_urlNotifyAgent, 'POST', _dataNotifyAgent);
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to notify the agent!'});
      return;
    }
    if(_ppt.sp && _ppt.sp.length>0){
      var _urlNotifySeller = 'buyer/book/notify/seller';
      var _dataNotifySeller = { 
        phoneNo: _ppt.sp,
        uid: _ppt.uid,
        pid: _ppt.pid,
        ag_n: _ppt.agProfile.n, 
        sid: _ppt.sid, s_n: _ppt.s, p_n: _ppt.n,
        bkDate: _bkDateT,
        bkTimeStart: _bkTimeStartT,
        bkTimeEnd: _bkTimeEndT
      };
      if(_oldBooking){
        _urlNotifySeller = 'buyer/edit/notify/seller';
        _dataNotifySeller.oldDate = _oldBooking.oldDate;
        _dataNotifySeller.oldTimeStart = _oldBooking.oldTimeStart;
        _dataNotifySeller.oldTimeEnd = _oldBooking.oldTimeEnd;
      }
      var _res = await AppHelper.api(_urlNotifySeller, 'POST', _dataNotifySeller);
      if(_res.status != 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to notify the seller!'});
        return;
      }
    }
    var _dateList = [...dateList];
    _dateList.forEach(dItem=>{
      dItem.hrList.map(hItem=>hItem.isBooked = false);
      if(dItem.v === copyDate.v){
        dItem.hrList.filter(hItem=>hItem.v===copyDate.bkHr && hItem.vm===copyDate.bkM).map(hItem=>hItem.isBooked = true);
      }
    });
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Booking submitted!'});
    _ppt.bProfile.bkDate = _ppt.bkDate;
    _ppt.bkHr = _ppt.bkHr;
    setProperty(_ppt);
    setIsModified(true);
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {isLoading? (
        <AppLoading />
      ):(
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'80vh', xs:'75vh'}, backgroundColor:'rgba(0,0,0,0.02)' }} elevation={3}>
          <Box m={2} style={{color:'#777'}}>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Box display="flex" justifyContent="flex-start">
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant=""
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {monthName}
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MonthDdlItems items={monthList} funOnClick={onMonthSelect} />
                  </StyledMenu>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box display="flex" justifyContent="flex-end" alignItems='center'>
                 
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <DateListItems items={dateList} funOnCheckDate={onDateClick} funOnHourClick={onHourClick} funOnCopy={onCopy} monthName={monthName} />
                </Box>
              </Grid>
              {/* <Grid item xs={12} mt={1}>
                <Button onClick={go2BuyerHome} color="info" variant='contained' sx={{}} size="large">
                  DONE
                </Button>
              </Grid> */}
            </Grid>
          </Box>
          
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
              showLabels
              // value={value}
              // onChange={(event, newValue) => {
              //   setValue(newValue);
              // }}
            >
              {!isModified && <BottomNavigationAction onClick={go2BuyerHome} label="BACK" icon={<KeyboardReturnIcon />} />}
              {isModified && <BottomNavigationAction onClick={go2BuyerHome} label="DONE" icon={<AssignmentTurnedInIcon />} />}
            </BottomNavigation>
          </Paper>
        </Paper>
      )}
      <CopyPopup
        open={copyOpen}
        onClose={copyClose}
        copyDate={copyDate}
        onBook={onBook}
        isReschedule={isReschedule}
      />
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </Container>
  );
}
