import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAddToHomescreenPrompt } from "../addToHomeScreen";
import { AppHelper } from "../../AppHelper";

export default function SpaCheck() {
  const navigate = useNavigate();
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isSpaApp, setIsSpaApp] = useState(false);
  const [isPwa, setIsPwa] = useState(false);
  var appVer = '';
  var appVerCheckId;

  useEffect(()=>{
    var _isAndroid = /Android/i.test(navigator.userAgent);
    setIsAndroid(_isAndroid);
    var _isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(_isIOS);
    var _isSpaApp = window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
    setIsSpaApp(_isSpaApp);
    var _isPwa = window.matchMedia('(display-mode: standalone)').matches;
    setIsPwa(_isPwa);

    if(!isPwa && (isIOS || isAndroid)){
      navigate('/add2HomeScreen');
    }
  });
  useEffect(()=>{
    const versionCheck = async ()=>{
      var _res = await AppHelper.api('api/version');
      if(_res && _res.status===1 && _res.data){
        if(appVer === '' || appVer === _res.data.v){
          appVer = _res.data.v;
          console.log('same-v:', appVer, appVerCheckId);
        }
        else{
          appVer = _res.data.v;
          console.log('v-changed:', appVer);
          if(window && 'caches' in window){
            caches.keys().then((names) => {
            names.forEach(name => {
                caches.delete(name);
            })
            })
          }
          window.location.reload(true);
        }
      }
      // if(appVerCheckId) window.clearTimeout(appVerCheckId);
      // appVerCheckId = window.setTimeout(() => {
      //     versionCheck();
      //   }, 1000*10);
    };
    //versionCheck();
    const timerIdCheck = setInterval(() => {
      versionCheck();
    }, 1000*10);
    return () => clearInterval(timerIdCheck);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection:'column' }}>
      {/* [SPA]
      <div>
        SPA App: {isSpaApp?'Y':'N'}
      </div>
      <div>
        PWA: {isPwa?'Y':'N'}
      </div>
      <div>
        Android: {isAndroid?'Y':'N'}
      </div>
      <div>
        iOS: {isIOS?'Y':'N'}
      </div>
      <div>
        {isAndroid && !isPwa && <button onClick={promptToInstall}>Add to Home Screen</button>}
      </div> */}
    </Box>
  );
}