import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import {Avatar, Button} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { auth } from '../../firebase';
import { useUser } from '../../UserContext';
import { AppHelper } from '../../AppHelper';
const configJson = require('../../config.json');

export default function AppTopBar() {
  const navigate = useNavigate();
  const [authOK, setAuthOK] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ oUser, setOUser] = React.useState(null);

  useEffect(()=>{
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);
    setOUser(_user);
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => { setAnchorEl(null) };
  const go2Home = () => {
    navigate('/home');
  }
  const go2Login = () => {
    navigate('/login');
  }
  const go2Profile = () => {
    navigate('/profile');
  }
  const go2Feedback = () => {
    navigate('/feedback');
  }
  const logMeOut = async() =>{
    if(!oUser){
      alert('No or invalid user!');
      return;
    }
    var _res = await AppHelper.api('user/logout', 'POST', {uid:oUser.uid});
    if(!_res || _res.status !== 1){
      if(_res)
        alert('ERROR: ' + _res.msg);
      AppHelper.userCacheSet();
      go2Login();
      return;
    }
    AppHelper.userCacheSet();
    //console.log('log out', oUser);
    go2Login();
    // auth().signOut().then(()=>{
    //   AppHelper.userCacheSet();
    //   go2Login();
    // })
    // .catch(err=>{
    //   console.log('ERROR', err);
    // });
  }
  const refreshMe = async() =>{
    if(window && 'caches' in window){
      caches.keys().then((names) => {
      names.forEach(name => {
          caches.delete(name);
      })
      })
    }
    window.location.reload(true);
  }
  const go2Dev = async() =>{
    navigate('/dev');
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Box onClick={go2Home} component='a' sx={{cursor:'pointer'}}>
              Ding Dong {configJson.mode!=='PROD'?'*DEV':''}
            </Box>
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          {
            authOK &&
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {(!oUser) ?
                  <AccountCircle fontSize='large' />
                  : <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
                  }
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={go2Profile}>Profile</MenuItem>
                  <MenuItem onClick={go2Home}>Home</MenuItem>
                  <MenuItem onClick={go2Feedback}>Feedback</MenuItem>
                  {/* <MenuItem onClick={refreshMe}>Refresh</MenuItem> */}
                  <MenuItem onClick={logMeOut}>Logout</MenuItem>
                  {/* <MenuItem onClick={go2Dev}>DEV</MenuItem> */}
                </Menu>
              </div>
          }
          {/* {
            !authOK &&
            <img src='../../logo-2.jpeg?v=20231101' style={{maxWidth:'130px'}} alt="Logo-2" />
          } */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}