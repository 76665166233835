import { createContext, useContext, useState } from 'react';

const PropertyContext = createContext();

export const useProperty = () =>{
  return useContext(PropertyContext);
};

export const PropertyProvider = ({ children })=>{
  var _tmp = {
    "uid": "qOqhbHhK0m80oImK3IbM4gEYYWQ1",
    "n": "Serangoon 550409",
    "s": "Saul",
    "sp": "+65 3333 3333",
    "pc": "550409",
    "address": {
        "country": "SG",
        "lat": 1.347921,
        "lng": 103.8743269,
        "address": "409 Serangoon Central, Singapore 550409"
    }
  };
  _tmp = null;
  const [property, setProperty] = useState(_tmp);

  const setPropertyData = (newPropertyData) => {
    setProperty(newPropertyData);
  };
  return (
    <PropertyContext.Provider value={{property, setProperty: setPropertyData}}>
      { children }
    </PropertyContext.Provider>
  )
}
