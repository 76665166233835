import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import AppFooterFeedback from './common/footer-feedback';
import '../css/property-list.css';
import { Avatar, Container, Paper, Button, Divider, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid 
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import PhoneNumber from 'libphonenumber-js';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import Fab from '@mui/material/Fab';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SpaCheck from './common/spa-check';

function PropertyListItems({ items, funOnClick }) {
  return (
    <List>
      {items.map((item, index) => (
        <div key={item.id}>
          <ListItem key={item.id} disablePadding>
            <ListItemButton onClick={() => funOnClick(item)} sx={{minWidth:300}}>
              {/* <ListItemText primary={item.n} /> */}
              <ListItemText
                primary={item.n}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      SELLER —
                    </Typography>
                    <span style={{marginLeft:5}}>{item.detail.s}</span><br/>
                    {/* <span style={{fontStyle:'italic', opacity:0.65}}>{item.sStatus}</span> */}
                    <span style={{fontStyle:'italic', color:item.sStatus&&item.sStatus.includes('Pending')?'#6f0202':'inherit', opacity:0.65}}>{item.sStatus}</span>
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default function PagePropertyList() {
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [viewCode, setViewCode] = useState('NO');
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState('SG'); // State to hold the selected value
  const [phoneNumber, setPhoneNumber] = useState('99998888');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('0');
  const [pName, setPName] = useState('');
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [readingData, setReadingData] = useState(true);
  const [records, setRecords] = useState([]);

  const [address, setAddress] = useState({country:'', lat:0, lng:0, address:''});
  const txtName = useRef(null),
    txtSeller = useRef(null),
    txtPostalCode = useRef(null);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [txtSellerOK, setTxtSellerOK] = useState(true);
  const [txtPhoneNoOK, setTxtPhoneNoOK] = useState(true);
  const [txtPostalCodeOK, setTxtPostalCodeOK] = useState(false);
  const { setProperty } = useProperty();

  useEffect(()=>{
    if(!oUser){
      navigate('/');
      return;
    } 
    setReadingData(true);
    const fetchCountryList = async() =>{
      countryList.splice(0);
      var _resCountries = await AppHelper.api('countries', 'GET');
      var _countryList = [];
      for (const [code, name] of Object.entries(_resCountries)) {
        _countryList.push({code, name: `${name} (${code})`});
      }
      setCountryList(_countryList);
    };
    const fetchRecords = async() =>{
      var _res = await AppHelper.api('property/list/detail', 'POST', {uid:oUser.uid});
      setReadingData(false);
      if(_res.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the list!'});
        return;
      }
      var _list = _res.data.filter(r=>r.detail);
      for(var i=0; i<_list.length; i++){
        _list[i].sStatus = AppHelper.itemSStatus(_list[i].detail && _list[i].detail.sProfile && _list[i].detail.sProfile.slSh);
      }
      setRecords(_list);
      fetchCountryList();
    };
    fetchRecords();
  }, [viewCode]);

  const handlePhoneNoChange = (event) => {
    const _phoneNo = event.target.value;
    if(_phoneNo.length>0){
      const _phoneNoParsed = PhoneNumber(_phoneNo, countryCode);
      if (_phoneNoParsed && _phoneNoParsed.isValid()) {
        const formattedNumber = _phoneNoParsed.formatInternational();
        setFormattedPhoneNumber(formattedNumber);
        setTxtPhoneNoOK(true);
      } else {
        setFormattedPhoneNumber(null);
        setTxtPhoneNoOK(false);
      }
      setPhoneNumber(_phoneNo);
    }
    else{
      setFormattedPhoneNumber('');
      setTxtPhoneNoOK(true);
    }
  };
  const handleCountrySelect = (event) => {
    setCountryCode(event.target.value);
  };

  const onAdd = async () => {
    setViewCode('NEW');
    formReset();
  };
  const onAddSubmit = async () => {
    var _validateOK = true;
    var _name = txtName.current.value;
    var _seller = txtSeller.current.value;

    if((!_name || _name.length<1)){
      _validateOK =  false;
      setTxtNameOK(false);
    }
    else setTxtNameOK(true);
    if(!_seller || _seller.length<1){
      _validateOK =  false;
      setTxtSellerOK(false);
    }
    else setTxtSellerOK(true);
    if(formattedPhoneNumber!='' && !formattedPhoneNumber){
      _validateOK = false;
      setTxtPhoneNoOK(false);
    }
    else setTxtPhoneNoOK(true);

    if(!address.address || address.address.length<1){
      _validateOK = false;
      setTxtPostalCodeOK(false);
    }
    else setTxtPostalCodeOK(true);
    if(!_validateOK){
      return;
    }

    var _data = {
      uid: oUser.uid,
      n: txtName.current.value,
      s: txtSeller.current.value,
      sp: formattedPhoneNumber,
      pc: txtPostalCode.current.value,
      address: address
    }
    // var _res = await AppHelper.api('property/add', 'POST', { uid: oUser.uid, data:_data });
    // if(_res.status != 1){
    //   setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
    //   return;
    // }
    // setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Property Added!'});
    // showList();
    setProperty(_data);
    navigate('/agent/schedule');
  };
  const onAddCancel = ()=>{
    showList();
  };
  const formReset = ()=>{
    setAddress({country:'', lat:0, lng:0, address:''});
    setFormattedPhoneNumber('');
    setTxtNameOK(true);
    setTxtPhoneNoOK(true);
    setTxtPostalCodeOK(true);
    setTxtSellerOK(true);
  }
  const showList = ()=>{
    setViewCode('NO');
  }
  const recordItemClick = (arg) => {
    navigate(`/property/detail/${arg.id}`);
  };
  const handlePostalCodeChange = async(arg) =>{
    setAddress({country:'', lat:0, lng:0, address:''});
    var _postalCode = txtPostalCode.current.value;
    if(_postalCode.length<6){
      setTxtPostalCodeOK(false);
      return;
    }
    var _res = await AppHelper.api('api/addressGet', 'POST', { address: _postalCode });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to get the address!'});
      setTxtPostalCodeOK(false);
      return;
    }
    if(_res.data && _res.data.address && _res.data.address.length>0){
      setAddress({country: _res.data.country, lat: _res.data.lat, lng: _res.data.lng, address: _res.data.address});
      setTxtPostalCodeOK(true);
    }
  };
  const onChangePName = ()=>{
    var _pName = txtName.current.value;
    if(_pName.length <= 30){
      setPName(_pName);
    }
    else{
      setPName(_pName.slice(0,30));
    }
  }

  return (
    <>
      <AppTopBar />
      <SpaCheck></SpaCheck>
      {viewCode=='NO'? (
        readingData?(
          <AppLoading />
        ):(
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            {(records && records.length>0)?(
              <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'} }} elevation={3}>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>LISTINGS</span>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span" variant="body2" color="text.primary"
                          >
                          </Typography>
                          {" — " + oUser.displayName}
                        </React.Fragment>
                      }
                    />
                    <ListItemAvatar>
                      {/* <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" /> */}
                      <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
                    </ListItemAvatar>
                    {/* <ListItemText primary={'Property List (' + records.length + ')'} /> */}
                  </ListItem>
                  <Divider/>
                  {
                    (!records || records.length<1)?(
                      <div>No record found! {records.length}</div>
                    ):(
                      <PropertyListItems items={records} funOnClick={recordItemClick} />
                    )
                  }
                </Box>
                
                <Box sx={{width: '100%'}} m={.5} display="flex" justifyContent="flex-end" alignItems='flex-end' pr={1}>
                  <Button onClick={onAdd} color="info" variant="outlined">ADD LISTING</Button>
                </Box>
                <Box p={2} pl={0} pr={0} alignItems="flex-start">
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ minHeight: '40vh' }}
                    width='100%'
                  >
                    <Grid item xs={12} md={12}>
                      
                    </Grid>
                    <Grid item pt={1.5} sx={{display:'flex', width:'100%'}}>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            ):(
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="80vh"
              >
                <Fab onClick={onAdd} sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
                  <div>
                    <div>ADD</div>
                    <div>Listing</div>
                  </div>
                </Fab>
              </Box>
            )}
            {
              // !isAppInstalled &&
              // <button onClick={promptToInstall}>Add to Home Screen</button>
            }
            {/* <button onClick={promptToInstall}>Add to Home Screen 2</button> */}
            
            <AppFooterFeedback/>
          </Container>
        )
      ):(
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'} }} elevation={3}>
            <Box p={2} pl={2} pr={2} alignItems="flex-start">
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>NEW LISTING</span>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {/* for {oUser.name} */}
                      </Typography>
                      {" — " + oUser.displayName}
                    </React.Fragment>
                  }
                />
                <ListItemAvatar sx={{minWidth:70, display:'flex', justifyContent:'center'}}>
                  <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
                </ListItemAvatar>
              </ListItem>
              <Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1}>
                    <TextField value={pName} inputRef={txtName} onChange={onChangePName} error={!txtNameOK} label="LISTING NAME" variant="outlined" fullWidth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1}>
                    <TextField inputRef={txtSeller} error={!txtSellerOK} label="SELLER NAME" variant="outlined" fullWidth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1.5}>
                    <FormControl fullWidth>
                      <InputLabel id='lblDdlCountry'>Country</InputLabel>
                      <Select labelId='lblDdlCountry' label="Country"
                        value={countryCode}
                        onChange={handleCountrySelect}
                      >
                        {countryList.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box m={.5} pt={1}>
                    <TextField onChange={handlePhoneNoChange} error={!txtPhoneNoOK} id="txtPhoneNo" label="Phone Number" variant="outlined" fullWidth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1}>
                    <TextField onChange={handlePostalCodeChange} error={!txtPostalCodeOK} inputRef={txtPostalCode} label="POSTAL CODE" variant="outlined" fullWidth />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1}>
                    <span style={{opacity:0.5, fontStyle:'italic'}}>{address.address}</span>
                  </Box>
                </Grid>
                <Grid container pt={.5}>
                  <Grid item xs={6}>
                    <Button onClick={onAddCancel} color="secondary">CANCEL</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button onClick={onAddSubmit} color="info" variant="outlined">NEXT</Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </>
  );
}
