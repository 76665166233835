import React from "react";

export default function AppDataPolicy() {
  return (
    <div style={{marginTop:'0.5rem', opacity:0.6, fontStyle:'italic', fontSize:'normal'}}>
      DingDong's use and transfer of information received from Google APIs to any other app will adhere to &nbsp;
      <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target='_blank' style={{textDecoration:'none'}}>
        Google API Services User Data Policy
      </a>, including the Limited Use requirements.  
    </div>
  );
}