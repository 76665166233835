import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar';
import AppLoading from './common/app-loading';
import { Paper, Checkbox, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Select, MenuItem, FormControl, InputLabel, TextField, Stack, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { auth } from '../firebase';
import { useUser } from '../UserContext';
import { AppHelper } from '../AppHelper';
import { useProperty } from '../contexts/context-property';
import PageAgentSellerSchedule from './agent-seller-schedule';
import PageBuyer from './buyer';
import PageBuyerSchedule from './buyer-schedule';
import PageSeller from './seller';
import PageSellerSchedule from './seller-schedule';
import PageSellerViewings from './seller-viewings';

export default function PageLand() {
  const navigate = useNavigate();
  const {code, id, id2} = useParams();
  const [viewCode, setViewCode] = useState('NEW');
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [msgText, setMsgText] = useState('');
  const { setProperty } = useProperty();
  const [pageRefresh, setPageRefresh] = useState(false);
  
  useEffect(()=>{
    if(!code) return;
    var _code = code.trim().toUpperCase();
    if(_code == 'S'){
      const fetchRecords = async() =>{
        var _res = await AppHelper.api('property/list', 'POST', {uid:id, code:_code});
        setViewCode('MSG');
        if(_res.status !== 1){
          setIsLoading(false);
          setMsgText('ERROR: Failed to load the data!');
          return;
        }
        if(_res.data.length<1){
          setIsLoading(false);
          setMsgText('ERROR: Data not found!');
          return;
        }
        setIsLoading(true);
        var _pid = _res.data[0].id;
        _res = await AppHelper.api('property/detail', 'POST', {pid:_pid});
        setIsLoading(false);
        if(_res.status !== 1){
          setMsgText('ERROR: Failed to load the data!');
          return;
        }
        var _rec = _res.data;
        _rec.pid = _pid;
        _rec.mode = 'S';
        setProperty(_rec);
        setViewCode('SELLER');
      };
      fetchRecords();
    }
    else if(_code == 'B'){
      fetchBuyerInfo(_code);
    }
    else if(_code == 'A'){
      const fetchRecords = async() =>{
        var _res = await AppHelper.api('property/list', 'POST', {uid:id2, code:_code});
        setViewCode('MSG');
        if(_res.status !== 1){
          setIsLoading(false);
          setMsgText('ERROR: Failed to load the data!');
          return;
        }
        if(_res.data.length<1){
          setMsgText('ERROR: Data not found!');
          return;
        }
        setIsLoading(true);
        var _pid = _res.data[0].id;
        _res = await AppHelper.api('property/detail', 'POST', {pid:_pid});
        setIsLoading(false);
        if(_res.status !== 1){
          setMsgText('ERROR: Failed to load the listing detail!');
          return;
        }
        var _rec = _res.data;
        _rec.pid = _pid;
        _rec.mode = 'S';
        if(_rec.sProfile && _rec.sProfile.slSh){
          _rec.slSh = _rec.sProfile.slSh;
        }
        setProperty(_rec);
        setViewCode('AGENT');
      };
      fetchRecords();
    }
    else{
      setIsLoading(false);
      setMsgText('ERROR: Invalid code!');
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Invalid code!'});
    }
    // if(!_useProperty.property){
    //   navigate('/property/list');
    //   return;
    // }
    // setMsgText(`Hi ${_useProperty.property.s}, please fill out your schedule availability so we can sell your property at ${_useProperty.property.n}!
    // https://app.dingdong.asia/land/s/${_useProperty.property.sid}`)
  }, [pageRefresh]);

  const fetchBuyerInfo = async(_code) =>{
    var _res = await AppHelper.api('property/list', 'POST', {uid:id, code:_code});
    setIsLoading(false);
    setViewCode('MSG');
    if(_res.status !== 1){
      setMsgText('ERROR: Failed to load the data!');
      return;
    }
    if(_res.data.length<1){
      setMsgText('ERROR: Data not found!');
      return;
    }
    setIsLoading(true);
    var _pid = _res.data[0].id;
    _res = await AppHelper.api('property/detail', 'POST', {pid:_pid});
    setIsLoading(false);
    if(_res.status !== 1){
      setMsgText('ERROR: Failed to load the data!');
      return;
    }
    var _rec = _res.data;
    _rec.pid = _pid;
    _rec.mode = 'B';
    _rec.bid = id;
    setProperty(_rec);
    setViewCode('BUYER');
  };

  const go2SellerHome = (reload=false)=>{
    setViewCode('SELLER');
    if(reload) setPageRefresh(true);
  }
  const sellerGo2Schedule = ()=>{
    setViewCode('S-SCH')
  };
  const sellerGo2Viewings = ()=>{
    setViewCode('S-VEW')
  };
  const buyerGo2Schedule = ()=>{
    setViewCode('B-SCH')
  };
  const go2BuyerHome = ()=>{
    setViewCode('BUYER');
    var _code = code.trim().toUpperCase();
    fetchBuyerInfo(_code);
  }

  return (
    <div>
      <AppTopBar2 />
      {isLoading?(
        <AppLoading />
      ):(
        viewCode==='MSG'?(
          <Box
            display="flex" mt={1}
              justifyContent="center" alignItems="center" minHeight="80vh"
          >
            <Paper elevation={3}>
              <Box p={2} pl={2} pr={2} alignItems="flex-start">
                {msgText}
              </Box>
            </Paper>
          </Box>
        ):
        viewCode==='AGENT'?(
          <PageAgentSellerSchedule go2Next={sellerGo2Schedule} go2Viewings={sellerGo2Viewings}/>
        ):
        viewCode==='SELLER'?(
          <PageSeller go2Next={sellerGo2Schedule} go2Viewings={sellerGo2Viewings}/>
        ):
        viewCode==='S-SCH'?(
          <PageSellerSchedule go2SellerHome={go2SellerHome}/>
        ):
        viewCode==='S-VEW'?(
          <PageSellerViewings go2SellerHome={go2SellerHome}/>
        ):
        viewCode==='BUYER'?(
          <PageBuyer go2Next={buyerGo2Schedule}/>
        ):
        viewCode==='B-SCH'?(
          <PageBuyerSchedule go2BuyerHome={go2BuyerHome}/>
        ):(
          <div>No data to show!</div>
        )
      )}
    </div>
  );
}
