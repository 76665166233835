import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Paper, Chip, Dialog, DialogTitle, TextField,
  BottomNavigation, BottomNavigationAction,
  IconButton, Button, Grid, Snackbar
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppHelper } from '../AppHelper';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AppLoading from './common/app-loading';
import { useProperty } from '../contexts/context-property';
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

function CopyPopup(props){
  const { onClose, open, bkDate, onBookCancel } = props;
  const [vDate, setVDate] = useState(bkDate);
  const handleClose = () =>{ onClose() };
  const txtReason = useRef(null);
  const [txtReasonOK, setTxtReasonOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  useEffect(() => {
    setVDate(props.bkDate);
  }, [props.bkDate]);

  const onCancel = async()=>{
    var _reason = txtReason.current.value;
    if((!_reason || _reason.length<1)){
      setTxtReasonOK(false);
      return;
    }
    else setTxtReasonOK(true);
    var _hr = vDate.hrList.find(h=>h.a===1);
    if(!_hr){
      alert('Please select the viewing slot to cancel!');
      return;
    }
    setIsSubmitting(true);
    await onBookCancel(bkDate, _hr, _reason);
    setIsSubmitting(false);
  }
  const onHourSelect = (vHr, iHr)=>{
    var _vDate = {...vDate};
    _vDate.hrList.forEach((vH, iVH)=>{
      if(iVH!=iHr) vH.a = 0;
      else vH.a = vH.a === 1 ? 0:1;
    });
    setVDate(_vDate);
  }

  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>CANCEL VIEWING</DialogTitle>
      <Box display='flex' flexDirection='column' alignItems='center' sx={{textTransform:'uppercase'}}>
        <span style={{fontWeight:'bold', fontSize:'1.05rem', textTransform:'uppercase'}}>
          ON {bkDate.dateD} {bkDate.MMMM} ({bkDate.dateDDDD})
        </span>
      </Box>
      {vDate && vDate.hrList && vDate.hrList.map((hr, iHr)=>(
        <Grid key={hr.ht} container>
          <Grid item xs={2} display='flex' justifyContent='flex-end'>
            <IconButton onClick={()=>onHourSelect(hr, iHr)} sx={{marginLeft:-1.5}}>
              {hr.a===1?(
                <RadioButtonCheckedIcon sx={{color:'#1976d2'}} />
              ):(
                <RadioButtonUncheckedIcon />
              )}
            </IconButton>
          </Grid>
          <Grid  item xs={10}>
            <Box display='flex' alignItems='center' justifyContent='flex-start' height='100%'>
              <Chip color="success" label={hr.ht} sx={{width:'80%', fontSize:'0.9rem'}} />
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <Grid item xs={12}>
          <Box m={.5} pt={1} p={2} pb={0.5}>
            <TextField inputRef={txtReason} error={!txtReasonOK} label="Reason to cancel" variant="outlined" fullWidth />
          </Box>
        </Grid>
        <Grid item xs={12} p={2} pt={1} pb={0.5}>
          {/* <Button color='info' onClick={onCancel} variant="contained" sx={{width:'100%'}}>CANCEL & NOTIFY AGENT</Button> */}
          <LoadingButton sx={{width:'100%'}}
            onClick={()=>{onCancel()}}
            // endIcon={<SendIcon />}
            loading={isSubmitting}
            //loadingPosition="start"
            variant="contained"
          >
            <span>CANCEL & NOTIFY AGENT</span>
          </LoadingButton>
        </Grid>
        <Grid item xs={12} p={2} pt={0} pb={0.5}>
          {!isSubmitting && 
          <Button onClick={handleClose} sx={{width:'100%'}}>BACK</Button>}
        </Grid>
      </Grid>
    </Dialog>
  );
}
CopyPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //copyDate: PropTypes.object.isRequired
};

export default function PageSellerViewings({go2SellerHome}) {
  const navigate = useNavigate();
  const [agSh, setAgSh] = useState({});
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  
  const _useProperty = useProperty();
  const [monthList, setMonthList] = useState([]);
  const [monthName, setMonthName] = useState('-');
  const [dateList, setDateList] = useState([]);
  const [viewings, setViewings] = useState([]);
  const [popOpen, setPopOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModified, setIsModified] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [copyOpen, setCopyOpen] = React.useState(false);
  const copyClose = ()=>{
    setCopyOpen(false);
  }
  const [copyDate, setCopyDate] = React.useState({hrList:[]});
  const [agBusyHours, setAgBusyHours] = React.useState([]);
  const [bkDate, setBkDate] = useState({});

  useEffect(()=>{
    if(!_useProperty.property){
      navigate('/');
      return;
    }
    setIsLoading(false);

    if(!_useProperty.property.b || _useProperty.property.b.length<1){
      navigate('/');
      return;
    }
    var _viewings = [...viewings];
    _useProperty.property.b.forEach(_b=>{
      if(_b.bkDate && _b.bkDate.length>0){
        var _viewTS = dayjs(_b.bkDate).add(_b.bkHr, 'hours').add(_b.bkM, 'minutes');
        //setViewingCount(_viewingCount);

        var _month = _viewTS.format('MMM');
        var _MMMM = _viewTS.format('MMMM');
        if(!_viewings.some(v=>v.m == _month)){
          _viewings.push({m:_month, MMMM:_MMMM, dates:[]});
        }
        var _viewM = _viewings.find(v=>v.m == _month);

        var _date = _viewTS.format('YYYY-MM-DD');
        var _dateD = _viewTS.format('D');
        var _dateDDD = _viewTS.format('ddd');
        var _dateDDDD = _viewTS.format('dddd');
        if(!_viewM.dates.some(d=>d.date == _date)){
          _viewM.dates.push({MMMM:_MMMM, date:_date, dateD:_dateD, dateDDD: _dateDDD, dateDDDD: _dateDDDD, hrList:[]});
        }
        var _viewDate = _viewM.dates.find(d=>d.date == _date);

        var _hh = _viewTS.format('HH');
        var _ht = _viewTS.format('hh:mmA');
        if(!_viewDate.hrList.some(h=>h.vH === _b.bkHr && h.vM===_b.bkM)){
          _viewDate.hrList.push({hh:_hh, ht:_ht, bid: _b.bid, vH: _b.bkHr, vM: _b.bkM});
        }
      }
    });
    setViewings(_viewings);

    setRecord(_useProperty.property);

    const fetchAgSh = async() =>{
      var _res = await AppHelper.api('property/item', 'POST', {uid:_useProperty.property.uid, code:'C', pid:_useProperty.property.pid});
      if(_res.status !== 1 || !_res.data || !_res.data.agSh){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Schedule!'});
        return;
      }
      setAgSh(_res.data);
      var _agSh = _res.data.agSh;

      var timeMin = _agSh.ds + 'T00:00:00Z';
      var timeMax = _agSh.de + 'T23:59:59Z';
      var _res = await AppHelper.api('user/calendar/freeBusy', 'POST', {uid:_useProperty.property.uid, timeMin, timeMax});
      if(_res.status !== 1 || !_res.data || !_res.data.calendars){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Calendar!'});
        return;
      }
      var _calendars = _res.data.calendars;
      var _calendarId = _res.data.calendarId;
      if(!_calendars[_calendarId]){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Calendar!'});
        return;
      }
      var _busyHours = [];
      if(_calendars[_calendarId].busy){
        _busyHours = _calendars[_calendarId].busy;
      }
      setAgBusyHours(_calendars[_calendarId].busy);
      //bindDates(_agSh, _busyHours);
    };
    fetchAgSh();
    // setMsgText(`Hi ${_useProperty.property.s}, please fill out your schedule availability so we can sell your property at ${_useProperty.property.n}!
    // https://app.dingdong.asia/land/s/${_useProperty.property.sid}`)
  }, []);
  const bindDates = (_agSh, _busyHours, monthSetAuto=true)=>{
    var _dateS = dayjs(_agSh.ds);
    var _dateE = dayjs(_agSh.de);
    var _dateList = [];
    var _monthList = [];
    var _isMonthSet = false;
    var _slShDateList = (_useProperty.property && _useProperty.property.slSh && _useProperty.property.slSh.d) ? _useProperty.property.slSh.d : [];
    while (_dateS.isBefore(_dateE) || _dateS.isSame(_dateE)) {
      var _monthName = _dateS.format('MMM');
      if(monthSetAuto && !_isMonthSet){
        setMonthName(_monthName);
        _isMonthSet = true;
      }
      var _hrList = [];
      for (let hour = 7; hour <= 21; hour++) {
        _hrList.push({v:hour, n:dayjs().hour(hour).format('hA')});
      }
      var _wd = _dateS.format('ddd').toUpperCase();
      if(!_agSh.any && _agSh.avail && _agSh.avail.length>0){
        var _arrAvail = _agSh.avail.filter(ad=>ad.d==_wd);
        if(_arrAvail.length<1){
          _hrList = [];
        }
        else{
          _hrList = _hrList.filter(hh=> hh.v >= _arrAvail[0].hf && hh.v <= _arrAvail[0].ht);
        }
      }
      if(_hrList.length>0){
        _hrList.forEach(_hr=>{
          var _hrDT = dayjs(_dateS).add(_hr.v, 'hours');
          _hr.agBusy = false;
          _busyHours.forEach(bhr=>{
            var _start = dayjs(dayjs(bhr.start).format('YYYY-MM-DDTHH:mm'));
            var _end = dayjs(dayjs(bhr.end).format('YYYY-MM-DDTHH:mm'));
            if(_hrDT.isBetween(_start, _end, 'second', '[]'))
              _hr.agBusy = true;
          });
        });
        var _dateItem = {
          v:_dateS.format('YYYY-MM-DD'), a: 0,
          n:_dateS.format('D'), n2:_wd,
          show: _monthName === monthName,
          hrList: [..._hrList],
          i: _dateList.length
        };
        var _arrSlsh = _slShDateList.filter(shd=>shd.v == _dateItem.v);
        if(_arrSlsh.length>0){
          _dateItem.hrList = [..._arrSlsh[0].hrList];
        }
        _dateList.push(_dateItem);
        var _m = _dateS.format('YYYY-MM');
        var _arr = _monthList.filter(r=>r.v === _m);
        if(_arr.length<1){
          _monthList.push({v:_m, n:_monthName});
        }
      }
      _dateS = _dateS.add(1, 'day');
    }
    setMonthList(_monthList);
    if(_monthList.length<1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to get the Month!'});
      return;
    }
    setDateList(_dateList);
  }
  const onCancelDate = (vDate, iVDate)=>{
    setBkDate(vDate);
    setPopOpen(true);
  }
  const onBookCancel = async(argDate, argHr, reason)=>{
    var _res = await AppHelper.api('seller/book/cancel', 'POST', {uid:_useProperty.property.uid, pid:_useProperty.property.pid, bid:argHr.bid, reason});
    if(_res.status !== 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to cancel the viewing. Please refresh and try again!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Viewing is cancelled!'});
    setPopOpen(false);
    var _viewings = [...viewings];
    _viewings.forEach(v=>{
      var _d = v.dates.find(d=>d.date === argDate.date);
      if(_d){
        _d.hrList = _d.hrList.filter(h=>h.vH !== argHr.vH && h.vM !== argHr.vM);
      }
      v.dates = v.dates.filter(d=>d.hrList && d.hrList.length>0);
    });
    setViewings(_viewings);
    setIsModified(true);
  }
  const popClose = ()=>{
    setPopOpen(false);
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {isLoading? (
        <AppLoading />
      ):(
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'}, backgroundColor:'rgba(0,0,0,0.02)' }} elevation={3}>
          <Box m={2} style={{color:'#777'}}>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  {viewings.map((vMonth, iVMonth) => (
                    <Grid key={vMonth.m} container>
                      <Grid item xs={12} display='flex' mb={1}>
                        {vMonth.MMMM}
                        {/* <IconButton onClick={()=>funOnCheckDate(item, index)} sx={{marginLeft:-1.5}}>
                          {item.a===1?(
                            <RadioButtonCheckedIcon sx={{color:'#1976d2'}} />
                          ):(
                            <RadioButtonUncheckedIcon />
                          )}
                        </IconButton> */}
                      </Grid>
                      {vMonth.dates.map((vDate, iVDate)=>(
                        <Grid key={vDate.date} container>
                          <Grid item xs={11}>
                            <Grid container sx={{border:'1px solid #efefef', backgroundColor:'#fff', borderRadius:'8px'}} mb={0.3} p={1}>
                              <Grid item xs={2}>
                                <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
                                  <Box>
                                    <div style={{fontWeight:'bold', fontSize:'1.5rem', textAlign:'center'}}>{vDate.dateD}</div>
                                    <div>{vDate.dateDDD}</div>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={10}>
                                {vDate.hrList.map((vHr,iVHr)=>(
                                  <Box display='flex' alignItems='center' key={vHr.ht} mb={0.3}>
                                    <Chip color="success" label={vHr.ht} sx={{width:'100%', fontSize:'0.9rem'}} />
                                  </Box>
                                  // <Button onClick={()=>funOnHourClick(index, iH)} key={hr.v} color='info' variant={hr.a===1?'contained':'outlined'} 
                                  //   sx={{paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:0, marginRight:0.2, marginBottom:0.2}}
                                  // >
                                  //   
                                  // </Button>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={1}  display='flex'>
                            <IconButton onClick={()=>onCancelDate(vDate, iVDate)} sx={{}}>
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))

                      }
                    </Grid>
                  ))}
                </Box>
              </Grid>
              {/* <Grid item xs={12} mt={1}>
                <Button onClick={go2SellerHome} color="info" variant='contained' size="large">
                  HOME
                </Button>
              </Grid> */}
            </Grid>
            
          </Box>
          
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
              showLabels
              // value={value}
              // onChange={(event, newValue) => {
              //   setValue(newValue);
              // }}
            >
              {!isModified && <BottomNavigationAction onClick={go2SellerHome} label="BACK" icon={<KeyboardReturnIcon />} />}
              {isModified && <BottomNavigationAction onClick={()=> go2SellerHome(true) } label="DONE" icon={<AssignmentTurnedInIcon />} />}
            </BottomNavigation>
          </Paper>
        </Paper>
      )}
      <CopyPopup
        open={popOpen}
        onClose={popClose}
        bkDate={bkDate}
        onBookCancel={onBookCancel}
      />
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </Container>
  );
}
