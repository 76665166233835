const configJson = require('./config.json?v=20230925');

export const AppHelper = {
  async api(endpoint, method = 'POST', data = null) {
    try {
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: data ? JSON.stringify(data) : null,
      };
      const res = await fetch(configJson.funUrl + '/' + endpoint, options);
      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message || 'Request failed');
      }
      return resData;
    } catch (error) {
      console.log('API ERROR', error);
    }
  },
  async apiDirect(argUrl, method='POST', data=null){
    try{
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: data ? JSON.stringify(data) : null,
      };
      const res = await fetch(argUrl, options);
      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message || 'Request failed');
      }
      return resData;
    }
    catch(err){
      console.log('API ERROR', err);
    }
  },
  sortList(argList, argField, argOrder=1){
    if(argOrder===1)
      return [...argList].sort((a, b) => a[argField] - b[argField]);
    else
      return [...argList].sort((a, b) => b[argField] - a[argField]);
  },
  userCacheSet(argUser=null){
    if(!argUser)
      localStorage.removeItem('loginUser');
    else localStorage.setItem('loginUser', JSON.stringify(argUser));
  },
  userCacheGetString(){
    return localStorage.getItem('loginUser');
  },
  userCacheGet(){
    var _json = this.userCacheGetString();
    if(!_json || _json.length<1) return null;
    return JSON.parse(localStorage.getItem('loginUser'));
  },
  userCodeCacheSet(argCode=null){
    if(!argCode)
      localStorage.removeItem('authCode');
    else localStorage.setItem('authCode', argCode);
  },
  userCodeCacheGet(){
    return localStorage.getItem('authCode');
  },
  itemSStatus(isReady){
    return isReady ? 'Seller schedule is confirmed' : 'Pending Seller schedule';
  }
  // sellerCacheGet(){
  //   var _json = this.userCacheGetString();
  //   if(!_json || _json.length<1) return null;
  //   return JSON.parse(localStorage.getItem('loginUser'));
  // },
  // sellerCacheSet(arg=null){
  //   if(!argCode)
  //     localStorage.removeItem('authCode')
  //   else localStorage.setItem('authCode', argCode);
  // }
};