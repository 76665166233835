import { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './firebase';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);  // User is signed in
      } else {
        setUser(null);  // User is signed out
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={user}>{children}</UserContext.Provider>
  );
};