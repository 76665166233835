import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import AppLoading from './common/app-loading';
import { Container, Paper, Button, Grid, Badge
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { AppHelper } from '../AppHelper';
import Fab from '@mui/material/Fab';
import { useProperty } from '../contexts/context-property';
import dayjs from "dayjs";

export default function PageSeller({go2Next, go2Viewings}) {
  const navigate = useNavigate();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isNew, setIsNew] = useState(true);
  const [record, setRecord] = useState({});
  
  const _useProperty = useProperty();
  const [agentProfile, setAgentProfile] = useState({});
  const [viewingCount, setViewingCount] = useState(0);
  const [viewCode, setViewCode] = useState('200');

  useEffect(()=>{
    var _ppt = _useProperty.property;
    if(!_ppt){
      navigate('/');
      return;
    }
    setIsLoading(false);
    setRecord(_useProperty.property);

    const fetchAgSh = async() =>{
      var _res = await AppHelper.api('property/item', 'POST', {uid:_useProperty.property.uid, code:'C', pid:_useProperty.property.pid});
      if(_res.status !== 1 || !_res.data || !_res.data.agSh){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Schedule!'});
        setViewCode('404');
        return;
      }
      var _agSh = _res.data.agSh;
      var _de = dayjs(_agSh.de);
      if(_de.isBefore(dayjs().format('YYYY-MM-DD'))){
        setViewCode('401');
        return;
      }
    };
    fetchAgSh();

    const fetchAgentProfile = async() =>{
      setIsLoading(true);
      var _res = await AppHelper.api('user/profile', 'POST', {uid:_useProperty.property.uid});
      setIsLoading(false);
      if(_res.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Profile!'});
        return;
      }
      setAgentProfile(_res.data);
      fetchSellerInfo();
    };
    fetchAgentProfile();

    const fetchSellerInfo = async()=>{
      setIsLoading(true);
      var _res = await AppHelper.api('property/item', 'POST', {uid:_useProperty.property.sid, code:'S', pid:_useProperty.property.pid});
      setIsLoading(false);
      if(_res.status !== 1 || !_res.data){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Seller Schedule!'});
        return;
      }
      if(!_res.data.slSh){
        setIsNew(true);
      }
      else{
        setIsNew(false);
        _useProperty.property.slSh = _res.data.slSh;
        _useProperty.setProperty(_useProperty.property);
        var _ppt = _useProperty.property;
        var _viewingCount = 0;
        if(_ppt.b && _ppt.b.length>0){
          _ppt.b.forEach(_b => {
            if(_b.bkDate && _b.bkDate.length>0){
              _viewingCount++;
              setViewingCount(_viewingCount);
            }
          });
        }
      }
    };
  }, []);

  const onGo2Next = ()=>{
    go2Next();
  };
  const onGo2Viewings = ()=>{
    go2Viewings();
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {isLoading? (
        <AppLoading />
      ):(
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'} }} elevation={3}>
          {viewCode==='200' && <Box style={{color:'#777'}}>
            <Grid>
              <Grid item >
                Hi, <span style={{color:'#666', fontWeight:'bold'}}>{record.s}</span>
              </Grid>
              <Grid item mt={2}>
              Your agent <span style={{color:'#333', fontWeight:'bold'}}>{agentProfile?agentProfile.n:'-'}</span> would like to know your availability for viewings for your property at <
                span style={{color:'#000', fontWeight:'bold'}}>{_useProperty.property.n}</span>.
              </Grid>
              <Grid item mt={3} mb={7}>
                {
                  isNew?(
                    <Box display='flex' pt={3} width='100%' alignItems='center' justifyContent='center'>
                      <Fab onClick={onGo2Next} sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
                        <div>
                          <div style={{fontSize:'1.5rem'}}>NEXT</div>
                        </div>
                      </Fab>
                    </Box>
                  ):(
                    <Box display='flex' flexDirection='column' mt={5} p={5}>
                      <Button color="info" onClick={onGo2Next} variant="contained" sx={{marginBottom:1}} size="large">MANAGE SCHEDULES</Button>
                      <Badge color="secondary" badgeContent={viewingCount} max={10} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} showZero>
                        <Button onClick={onGo2Viewings} disabled={viewingCount<1} color="info" variant="contained" size="large" fullWidth>VIEWING SCHEDULES</Button>
                      </Badge>
                    </Box>
                  )
                }
              </Grid>
            </Grid>
          </Box>}
          {viewCode==='404' && <Box>
            Data not found!
          </Box>}
          {viewCode==='401' && <Box>
            Viewing schedule has expired. Please get in-touch with your agent <span style={{fontWeight:'bold'}}>{agentProfile.n}</span>.
          </Box>}
        </Paper>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </Container>
  );
}
