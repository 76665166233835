//import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
//import { db } from "./firebase";
//import { auth } from './firebase';
import { Container, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { GoogleLogin } from '@react-oauth/google';

import AppTopBar from './components/common/app-top-bar';
import AppDataPolicy from './components/common/app-data-policy';

import Fab from '@mui/material/Fab';
import { AppHelper } from './AppHelper';
import SpaCheck from './components/common/spa-check';
const configJson = require('./config.json');
// const pageCss ={ fab: { width: "880px", height: "880px" } };

export default function App() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });

  useEffect(() => {
    setIsLoading(true);
    const urlSearchParams = new URLSearchParams(window.location.search);
    setSearchParams(urlSearchParams);
    var _authCode = searchParams.get("code");
    var _authScope = searchParams.get("scope");
    if(!_authCode || !_authScope){
      setIsLoading(false);
      // window.gapi.load('auth2', () => {
      //   console.log('gapi auth2');
      //   window.gapi.auth2.init({
      //     client_id: '114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com',
      //   });
      // });
      if(window.google && window.google.accounts){
        window.google.accounts.id.initialize({
          client_id: '114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com',
          //callback: handleCredentialResponse
        });
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { 
            theme: "filled_blue", size: "large", type:'standard', shape:'pill', 
            login_uri: configJson.loginUrl,
            ux_mode: 'redirect',
            //redirect_uri: 'https://dev.dingdong.asia',//'http://localhost:3000',//configJson.loginUrl,
            scope: 'email%20profile%20https://www.googleapis.com/auth/calendar.calendars.readonly%20https://www.googleapis.com/auth/calendar.events.owned&access_type=offline'
          }  // customization attributes
        );
      }
      return;
    }
    AppHelper.api('user', 'POST', {code: _authCode}).then(_resUser=>{
      setIsLoading(false);
      if(_resUser.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to login.'});
        return;
      }
      if(!_resUser.data || !_resUser.data.accessToken || !_resUser.data.accessToken.scope){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the login data.'});
        return;
      }
      if(!_resUser.data.accessToken.scope.includes('https://www.googleapis.com/auth/calendar')){
        //setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to access the calendar data. Please make sure it is checked for Google Calendar access.'});
        setMsgBox({...msgBox, open: true, msg: 'Please allow access to Google Calendar during Login.'});
        return;
      }
      AppHelper.userCacheSet(_resUser.data);
      AppHelper.userCodeCacheSet(_authCode);
      navigate('/home');
    });
  }, []);

  useEffect(()=>{
    const oUser = AppHelper.userCacheGet();
    if(oUser != null){
      navigate('/home');
    }
  });
  const onLoginGoogleBtn = async(event)=>{
    console.log(event);
    //event.preventDefault();
    setIsLoading(true);
    window.location.href = configJson.loginUrl;
    event.stopPropagation();
    //event.stopImmediatePropagation();
  }
  const onLogin = async (event) => {
    console.log(event);
    //event.preventDefault();
    setIsLoading(true);
    // const provider = new auth.GoogleAuthProvider();
    // try {
    //   var _res = await auth().signInWithPopup(provider);
    //   const _uid = _res.user.uid;
    //   var _resUser = await AppHelper.api('user/byUuid', 'POST', {uid: _uid});
    //   if(_resUser.status != 1){
    //     alert('ERROR: Failed to get the user detail!');
    //     return;
    //   }
    //   AppHelper.userCacheSet(_resUser.data);
    //   //var _res = await auth().signInWithRedirect(provider);
    //   //console.log('Auth DONE <<', _res);
    //   // var oUser = auth().currentUser;
    //   navigate('/home');
    // } catch (e) {
    //   console.log(e);
    // }
  };

  return (
    <>
      <AppTopBar />
      <SpaCheck></SpaCheck>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        {isLoading?(
          <Box display='flex' alignItems='center' justifyContent='center' minHeight='100vh'>
            <CircularProgress />
          </Box>
        ):(
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="80vh"
            flexDirection='column'
          >
            {/* <a href="https://accounts.google.com/o/oauth2/v2/auth?client_id=223546144272-3mp6i7ojg4nr78mucq0612gqdcbesta6.apps.googleusercontent.com&redirect_uri=http://localhost:5000/auth/callback&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/calendar&access_type=offline"
              class="btn btn-primary">Login with Google
            </a> */}
            <div>
              <img src='./logo-login.png?v=20231101' style={{maxWidth:'180px', marginTop:'-12rem', marginBottom:'1rem'}} />
            </div>
            {/* <div id="g_id_onload"
                data-client_id="114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com"
                data-login_uri={configJson.loginUrl}
                data-auto_prompt="false">
            </div> */}
            {/* <a onClick={onLogin} href={configJson.loginUrl} style={{textDecoration:'none'}}>
              
            </a> */}
            <div onClick={onLoginGoogleBtn} style={{position:'relative'}}>
              <div id="buttonDiv" className="g_id_signin" style={{transform: 'scale(1,1)'}}>
              </div>
              <div onClick={onLoginGoogleBtn} style={{position:'absolute', top:0, left:0, width:'100%', backgroundColor:'rgba(0,0,0,0)', height:'100%', cursor:'pointer' }}></div>
            </div>
            {/* <GoogleLogin
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
              ux_mode='redirect'
              redirect_uri={configJson.loginUrl}
            /> */}
            {/* <div>
              <a onClick={onLogin} href={configJson.loginUrl} className="btn btn-primary glitch">
                <Fab sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
                  <div>
                    <div>
                      <LockOpenIcon />
                    </div>
                    <div>
                      Login
                    </div>
                  </div>
                </Fab>
              </a>
            </div> */}
            {/* <a href="https://accounts.google.com/o/oauth2/v2/auth?client_id=223546144272-3mp6i7ojg4nr78mucq0612gqdcbesta6.apps.googleusercontent.com&redirect_uri=https://mindful-origin-398206.web.app&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/calendar&access_type=offline"
              className="btn btn-primary">
              <Fab sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
                Login
              </Fab>
            </a> */}
            {/* <Fab onClick={onLogin} sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
              Login
            </Fab> */}
          </Box>
        )}
        <Box
            component="footer"
            sx={{
              py: 1,
              px: 2,
              mt: 'auto',
              backgroundColor:'#fff'
              // backgroundColor: (theme) =>
              //   theme.palette.mode === 'light'
              //     ? theme.palette.grey[200]
              //     : theme.palette.grey[800],
            }}
          >
            <Container maxWidth="sm">
              <Box display='flex' justifyContent='center'>
                  <a href='mailto:contactus@dingdong.asia' style={{textDecoration:'none'}}>contactus@dingdong.asia</a>
              </Box>
              <AppDataPolicy />
            </Container>
          </Box>
      </Container>
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </>
  );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}
