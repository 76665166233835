import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import SpaCheck from './common/spa-check';
import '../css/property-list.css';
import { Avatar, Container, Paper, Button, Divider, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, Stack
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useAddToHomescreenPrompt } from "./addToHomeScreen";

export default function PageAdd2HomeScreen() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });

  const [address, setAddress] = useState({country:'', lat:0, lng:0, address:''});
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isSpaApp, setIsSpaApp] = useState(false);
  const [isPwa, setIsPwa] = useState(false);

  useEffect(()=>{
    var _isAndroid = /Android/i.test(navigator.userAgent);
    setIsAndroid(_isAndroid);
    var _isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(_isIOS);
    var _isSpaApp = window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
    setIsSpaApp(_isSpaApp);
    var _isPwa = window.matchMedia('(display-mode: standalone)').matches;
    setIsPwa(_isPwa);
  });

  useEffect(()=>{
  }, []);
  const onAddCancel = ()=>{
    window.history.back();
  };
  const onAdd2HomeScreen = ()=>{
    //Your Ding Dong App is now installing. Please check your Android Home Screen to open the App.
  };

  return (
    <>
      <AppTopBar />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Add to Home Screen
            </Typography>
            {isIOS?(
            <React.Fragment>
              <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Tap the share icon at the bottom of your screen, scroll down the pop-up menu till you see 'Add to home screen' and hit add.
              </Typography>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                {/* <Button variant="contained">Main call to action</Button> */}
                <Button variant="outlined" endIcon={<IosShareIcon />} disabled>Tap the below icon button</Button>
              </Stack>
            </React.Fragment>
            ):(
              <React.Fragment>
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                  To optimize your experience, we recommend adding the app to your device's home screen.
                </Typography>
                <Stack
                  sx={{ pt: 4 }}
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                >
                  <Button onClick={promptToInstall} variant="contained">Add to Home Screen</Button>
                  {/* <Button variant="outlined" endIcon={<IosShareIcon />} disabled>Tap the below icon button</Button> */}
                </Stack>
              </React.Fragment>
            )}
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          <Grid container spacing={4}>
          </Grid>
        </Container>
      </main>
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </>
  );
}
