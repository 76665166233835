import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import { Avatar, Button, Fab, Container, Paper, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import PhoneNumber from 'libphonenumber-js';
import { AppHelper } from '../AppHelper';

export default function PagePhone() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const isPhoneVerifed = oUser && oUser.phoneNumber && oUser.phoneNumber.length>0;
  
  const [viewCode, setViewCode] = useState('PHONE');
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState('SG');
  const [phoneNumber, setPhoneNumber] = useState('99998888');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('0');
  const [flagUrl, setFlagUrl] = useState('https://flagcdn.com/w320/sg.png');
  const [txtPhoneNoOK, setTxtPhoneNoOK] = useState(true);

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const [otpErrors, setOtpErrors] = useState([false, false, false, false, false, false]);

  useEffect(()=>{
    // if(isPhoneVerifed){
    //   navigate('/property/list');
    //   console.log(oUser);
    // }else{
      
    // }
  });

  useEffect(()=>{
    const fetchCountryList = async() =>{
      countryList.splice(0);
      setIsLoading(true);
      var _resCountries = await AppHelper.api('countries', 'GET');
      setIsLoading(false);
      var _countryList = [];
      for (const [code, name] of Object.entries(_resCountries)) {
        _countryList.push({code, name: `${name} (${code})`});
      }
      setCountryList(_countryList);
    };
    fetchCountryList();
  }, []);

  const handleCountrySelect = (event) => {
    setCountryCode(event.target.value);
    setFlagUrl(`https://flagcdn.com/w320/${event.target.value.toLowerCase()}.png`);
  };
  const handlePhoneNoChange = (event) => {
    const _phoneNo = event.target.value;
    const _phoneNoParsed = PhoneNumber(_phoneNo, countryCode);
    if (_phoneNoParsed && _phoneNoParsed.isValid()) {
      const formattedNumber = _phoneNoParsed.formatInternational();
      setFormattedPhoneNumber(formattedNumber);
      setTxtPhoneNoOK(true);
    } else {
      setFormattedPhoneNumber('-');
      setTxtPhoneNoOK(false);
    }
    setPhoneNumber(_phoneNo);
  };
  const onSubmitPhone = async () => {
    if(formattedPhoneNumber === '0' || formattedPhoneNumber.length<5){
      //alert('Invalid Phone Number!');
      setTxtPhoneNoOK(false);
      return;
    }
    else setTxtPhoneNoOK(true);

    setIsLoading(true);
    var _res = await AppHelper.api('user/phoneNo/otp', 'POST', {uid: oUser.uid, phoneNo: formattedPhoneNumber});
    setIsLoading(false);
    if(_res.status != 1){
      if(_res.msg === 'DUPLICATE'){
        alert('ERROR: Phone Number is already in-use by existing User!');
      }
      else alert('ERROR: Failed to send OTP!');
      return;
    }
    // AppHelper.userCacheSet(_res.data);
    setViewCode('OTP');
    window.setTimeout(()=>{
      if(otpRefs && otpRefs.length>0)
        otpRefs[0].current.focus();
    }, 500);
  };
  const onSubmitOTP = async () => {
    if(formattedPhoneNumber === '0' || formattedPhoneNumber === '-'){
      alert('Invalid Phone Number!');
      return;
    }
    var _otp = '';
    var _errors = [...otpErrors];
    otpRefs.forEach((_ref, i)=>{
      var _txt = _ref.current.value;
      _errors[i] = _txt.length<1;
      _otp += _txt;
    });
    setOtpErrors(_errors);
    if(_otp.length<6){
      return;
    }
    setIsLoading(true);
    var _res = await AppHelper.api('user/phoneNo/save', 'POST', {uid: oUser.uid, phoneNo: formattedPhoneNumber, n: oUser.displayName, otp: _otp});
    setIsLoading(false);
    if(_res.status != 1){
      alert('ERROR: Update failed or OTP failed!');
      return;
    }
    AppHelper.userCacheSet({ ...oUser, phoneNumber: _res.data.phoneNumber });
    navigate('/calendar/list');
  };
  const otpBackspace = (argEvent, argIndex)=>{
    if (argEvent.key === 'Backspace' && argIndex > 0 && argEvent.target.value === '') {
      otpRefs[argIndex - 1].current.focus();
      otpRefs[argIndex - 1].current.select();
    }
    else if(argEvent.key === 'ArrowLeft' && argIndex > 0){
      otpRefs[argIndex - 1].current.focus();
      otpRefs[argIndex - 1].current.select();
    }
    else if(argEvent.key === 'ArrowRight' && argIndex < otpRefs.length-1){
      otpRefs[argIndex + 1].current.focus();
      otpRefs[argIndex + 1].current.select();
    }
  }
  const otpPaste = (argEvent, argIndex)=>{
    argEvent.preventDefault();
    const pastedText = argEvent.clipboardData.getData('text');
    const pastedDigits = pastedText.split('');
    pastedDigits.forEach((digit, i) => {
      if (argIndex + i < otpRefs.length) {
        otpRefs[argIndex + i].current.value = digit;
      }
      otpRefs[argIndex].current.select();
    });
  }
  const otpInput = (argEvent, argIndex)=>{
    var _value = argEvent.target.value;
    var _errors = [...otpErrors];
    if (_value && argIndex < otpRefs.length - 1) {
      _errors[argIndex] = false;
      otpRefs[argIndex + 1].current.focus();
      otpRefs[argIndex + 1].current.select();
    }
    else _errors[argIndex] = true;
    setOtpErrors(_errors);
  };

  return (
    <>
      <AppTopBar />
      {isLoading? (
        <AppLoading />
      ):(
        <Container component="main" maxWidth="sm" sx={{ mb: 5 }}>
          <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} elevation={3}>
            <Box display='flex' justifyContent='center' alignItems='center' sx={{mb: {xs:3, md:6}}}>
                {flagUrl &&
                  <Avatar src={flagUrl} referrerPolicy="no-referrer" sx={{marginRight:'5px', border:'1px solid #d3d3d3'}} />
                }
                <Typography component="h2" variant="h5" align="center" >
                  {viewCode=='PHONE'?'VERIFY PHONE NUMBER': 'VERIFY PHONE NUMBER'}
                </Typography>
            </Box>
            {viewCode=='PHONE'? (
              <Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={0}>
                    <FormControl fullWidth>
                      <InputLabel id='lblDdlCountry'>Country</InputLabel>
                      <Select labelId='lblDdlCountry'
                        value={countryCode}
                        onChange={handleCountrySelect}
                        label="Country"
                      >
                        {countryList.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <p>Selected Value: {selectedValue}</p> */}
                  </Box>
                  <Box m={.5} pt={1} display='flex' alignItems='center'>
                    <TextField onChange={handlePhoneNoChange} id="txtPhoneNo" error={!txtPhoneNoOK} label="Phone Number" variant="outlined" fullWidth />
                  </Box>
                  {/* <Box m={.5} pt={1}>
                    {formattedPhoneNumber}
                  </Box> */}
                </Grid>
                <Grid item xs={12} pt={.5}>
                  <Box m={.5} display="flex" justifyContent="center" mt={4}>
                    {/* <Button onClick={onSubmitPhone} color="info" variant="outlined">Submit</Button> */}
                    <a onClick={onSubmitPhone} className="btn btn-primary">
                      <Fab sx={{width:'10rem', height:'10rem'}} color="primary" aria-label="Login">
                        Get OTP
                      </Fab>
                    </a>
                  </Box>
                </Grid>
              </Grid>
              // <Box
              //   display="flex"
              //   justifyContent="center"
              //   alignItems="center"
              //   minHeight="85vh"
              // ></Box>
            ):(
              <Grid>
                <Grid item xs={12} sm={6}>
                  <Box m={.5} pt={1} display='flex' justifyContent='center'>
                    {/* <TextField onChange={handleOTPChange} id="txtOTP" label="Enter OTP" variant="outlined" fullWidth /> */}
                    {otpRefs.map((inputRef, index) => (
                      <TextField sx={{width:'50px', textAlign:'center', marginRight:'3px'}}
                        key={index}
                        inputRef={inputRef}
                        variant="outlined"
                        onKeyUp={(e) => otpBackspace(e, index)}
                        onPaste={(e) => otpPaste(e, index)}
                        onChange={(e) => otpInput(e, index)}
                        inputProps={{ maxLength: 1 }} // Limit input to one character
                        error={otpErrors[index]}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} pt={.5}>
                  <Box m={.5} display="flex" justifyContent="center" mt={4}>
                    {/* <Button onClick={onSubmitOTP} color="info" variant="outlined">Submit</Button> */}
                    <a onClick={onSubmitOTP} className="btn btn-primary">
                      <Fab sx={{width:'10rem', height:'10rem'}} color="primary" aria-label="Login">
                        VERIFY
                      </Fab>
                    </a>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Container>
      )}
    </>
  );
}
