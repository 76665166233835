import React, {useState, useEffect} from "react";
import { Avatar, Box, Container, Paper, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Divider 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AppHelper } from '../AppHelper';
import AppTopBar from './common/app-top-bar';
import AppFooterFeedback from './common/footer-feedback';
import AppDataPolicy from './common/app-data-policy';

export default function PageProfile() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [calendarActive, setCalendarActive] = useState('...');
  const [appVer, setAppVer] = useState('-');

  useEffect(()=>{
    AppHelper.api('user/calendar/get', 'POST', {uid: oUser.uid}).then(_res=>{
      if(_res.status !== 1){
        //setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the user selected calendar!'});  
        return;
      }
      if(_res.data && _res.data.calendarId)
        setCalendarActive(_res.data.calendarName);
      else setCalendarActive('[ Click to Configure ]');
    });
  }, []);
  
  useEffect(()=>{
    const versionCheck = async ()=>{
      var _res = await AppHelper.api('api/version');
      if(_res && _res.status===1 && _res.data){
        setAppVer(_res.data.v);
      }
    };
    versionCheck();
  }, []);

  const go2CalendarList = ()=>{
    navigate('/calendar/list');
  }

  return (
    <div>
      <AppTopBar />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'80vh', xs:'75vh'} }} elevation={3}>
          <nav aria-label="main mailbox folders">
            <List>
            <ListItem>
              <ListItemAvatar>
                {/* <Avatar>
                  <AccountBoxIcon />
                </Avatar> */}
                <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
                {/* <img src={oUser.picture} referrerpolicy="no-referrer" /> */}
              </ListItemAvatar>
              <ListItemText primary={oUser.displayName} secondary={(oUser.metadata && oUser.metadata.lastSignInTime) ? oUser.metadata.lastSignInTime : '' } />
            </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText primary={oUser.displayName} />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={oUser.email} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <PermPhoneMsgIcon />
                  </ListItemIcon>
                  <ListItemText primary={oUser.phoneNumber} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={go2CalendarList}>
                  <ListItemIcon>
                    <CalendarMonthIcon />
                  </ListItemIcon>
                  <ListItemText primary={calendarActive} />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
          <Divider />
          <nav aria-label="secondary mailbox folders">
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary={'Last Login: ' + ((oUser.metadata && oUser.metadata.lastSignInTime) ? oUser.metadata.lastSignInTime : '')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary={appVer} sx={{color:'rgba(0,0,0,0.3)', textAlign:'right'}}  primaryTypographyProps={{fontSize: '0.7rem', fontStyle:'italic'}}/>
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Spam" />
                </ListItemButton>
              </ListItem> */}
            </List>
          </nav>

          <Box
            component="footer"
            sx={{
              py: 1,
              px: 2,
              mt: 'auto',
              backgroundColor:'#fff'
            }}
          >
            <Container maxWidth="sm">
              <AppDataPolicy />
            </Container>
          </Box>
        </Paper>
        <AppFooterFeedback/>
      </Container>
    </div>
  );
}
