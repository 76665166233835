import React from "react";
import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';

export default function AppLoading() {

  return (
    <Grid container
      spacing={0}
      direction="column" alignItems="center" justifyContent="center"
      sx={{ minHeight: '90vh' }}
    >
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </Grid>
  );
}