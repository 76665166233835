import React, {useState, useEffect} from "react";
import dayjs from 'dayjs';
import { Avatar, Box, Container, Paper, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Divider, 
  Button, Select, MenuItem, FormControl, InputLabel, TextField, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MessageIcon from '@mui/icons-material/Message';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { AppHelper } from '../AppHelper';
import AppTopBar2 from './common/app-top-bar';

export default function PageMsgLog() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [records, setRecords] = useState([]);
  const [dataDate, setDataDate] = useState('');
  const [calendarActive, setCalendarActive] = useState('...');
  const dateMin = dayjs(new Date()).add(-1, 'year');
  const dateMax = dayjs(new Date());
  const [dateTo, setDateTo] = useState(null);

  useEffect(()=>{
    const fetchLogs = async()=>{
      var _date = dayjs().format('YYYY-MM-DD');
      if(dateTo) _date = dateTo.format('YYYY-MM-DD');
      AppHelper.api('msg/log', 'POST', {date:_date}).then(_res=>{
        if(_res.status !== 1){
          //setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the user selected calendar!'});
          alert('ERROR: Failed to load the data.');
          return;
        }
        //console.log('res',_res);
        setRecords(_res.data);
      });
    };
    fetchLogs();
  }, [dateTo]);

  
  const dateToChoose = (arg)=>{
    setDateTo(arg);
    // if(dateFrom){
    //   if(dayjs(arg).isBefore(dateFrom))
    //     setDateFrom(arg);
    //   dateFromToChoose(dateFrom, arg);
    // }
  }

  const go2CalendarList = ()=>{
    navigate('/calendar/list');
  }

  return (
    <div>
      <AppTopBar2 />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'} }} elevation={3}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemText
              primary={
                <React.Fragment>
                  <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>MESSAGES</span>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span" variant="body2" color="text.primary"
                  >
                  </Typography>
                  {" — " + records.length + ' message' + (records.length>0? 's':'')}
                </React.Fragment>
              }
            />
            <ListItemAvatar>
                <MessageIcon />
            </ListItemAvatar>
          </ListItem>
          <Divider/>
        </Box>
        <Box m={.5} pt={1}>
          <DatePicker error={true} sx={{width:'100%'}}
            label="DATE"
            minDate={dateMin} maxDate={dateMax}
            value={dateTo}
            onChange={(newDateTo) => dateToChoose(newDateTo)}
          />
        </Box>
          <nav aria-label="main mailbox folders">
            <List>
              {/* <ListItem>
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText primary={'MESSAGE LOG'} secondary={''} />
              </ListItem> */}
              <React.Fragment>
                {records.map(r=>(
                  <ListItem key={r.id}>
                    <ListItemIcon>
                      <MarkChatReadIcon />
                    </ListItemIcon>
                    <ListItemText primary={r.n} secondary={r.t} />
                  </ListItem>
                  )) 
                }
              </React.Fragment>
            </List>
          </nav>
          <Divider />
          {/* <nav aria-label="secondary mailbox folders">
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary={'Last Login: ' + ((oUser.metadata && oUser.metadata.lastSignInTime) ? oUser.metadata.lastSignInTime : '')} />
                </ListItemButton>
              </ListItem>
            </List>
          </nav> */}
        </Paper>
      </Container>
    </div>
  );
}
