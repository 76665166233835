import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import '../css/property-list.css';
import { Avatar, Container, Paper, Button, Divider, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid 
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import PhoneNumber from 'libphonenumber-js';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import Fab from '@mui/material/Fab';
import AssignmentIcon from '@mui/icons-material/Assignment';

function PropertyListItems({ items, funOnClick }) {
  return (
    <List>
      {items.map((item, index) => (
        <div key={item.id}>
          <ListItem key={item.id} disablePadding>
            <ListItemButton onClick={() => funOnClick(item)} sx={{minWidth:300}}>
              {/* <ListItemText primary={item.n} /> */}
              <ListItemText
                primary={item.n}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      SELLER —
                    </Typography>
                    <span style={{marginLeft:5}}>{item.detail.s}</span><br/>
                    <span style={{fontStyle:'italic', opacity:0.65}}>{item.sStatus}</span>
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default function PageProperty() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [viewCode, setViewCode] = useState('NO');
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState('SG'); // State to hold the selected value
  const [phoneNumber, setPhoneNumber] = useState('99998888');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('0');
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [isEdit, setIsEdit] = useState(true);
  const [readingData, setReadingData] = useState(true);
  const [records, setRecords] = useState([]);

  const [address, setAddress] = useState({country:'', lat:0, lng:0, address:''});
  const txtName = useRef(null),
    txtSeller = useRef(null),
    txtPhoneNo = useRef(null),
    txtPostalCode = useRef(null);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [txtSellerOK, setTxtSellerOK] = useState(true);
  const [txtPhoneNoOK, setTxtPhoneNoOK] = useState(true);
  const [txtPostalCodeOK, setTxtPostalCodeOK] = useState(true);
  const { property, setProperty } = useProperty();

  useEffect(()=>{
    if(!property){
      navigate('/');
      return;
    }
    txtName.current.value = property.n;
    txtSeller.current.value = property.s;
    if(property.sp && property.sp.length>1){
      const _phoneNoParsed = PhoneNumber(property.sp);
      if (_phoneNoParsed && _phoneNoParsed.isValid()) {
        txtPhoneNo.current.value = _phoneNoParsed.formatNational();
      }
    }
    txtPostalCode.current.value = property.pc;
    if(property.address)
      setAddress(property.address);
  }, []);

  useEffect(()=>{
    const fetchCountryList = async() =>{
      countryList.splice(0);
      var _resCountries = await AppHelper.api('countries', 'GET');
      var _countryList = [];
      for (const [code, name] of Object.entries(_resCountries)) {
        _countryList.push({code, name: `${name} (${code})`});
      }
      setCountryList(_countryList);
    };
    fetchCountryList();
  }, []);

  const handlePhoneNoChange = (event) => {
    const _phoneNo = event.target.value;
    if(_phoneNo.length>0){
      const _phoneNoParsed = PhoneNumber(_phoneNo, countryCode);
      if (_phoneNoParsed && _phoneNoParsed.isValid()) {
        const formattedNumber = _phoneNoParsed.formatInternational();
        setFormattedPhoneNumber(formattedNumber);
        setTxtPhoneNoOK(true);
      } else {
        setFormattedPhoneNumber(null);
        setTxtPhoneNoOK(false);
      }
      setPhoneNumber(_phoneNo);
    }
    else{
      setFormattedPhoneNumber('');
      setTxtPhoneNoOK(true);
    }
  };
  const handleCountrySelect = (event) => {
    setCountryCode(event.target.value);
  };
  const onAddSubmit = async () => {
    if(!isDataOK()) return;
    var _data = {
      uid: oUser.uid,
      n: txtName.current.value,
      s: txtSeller.current.value,
      sp: formattedPhoneNumber,
      pc: txtPostalCode.current.value,
      address: address
    }
    setProperty(_data);
    navigate('/agent/schedule');
  };
  const onUpdate = async()=>{
    if(!isDataOK()) return;
    var _data = {
      uid: oUser.uid,
      pid: property.pid,
      n: txtName.current.value,
      s: txtSeller.current.value,
      sp: formattedPhoneNumber,
      pc: txtPostalCode.current.value,
      address: address
    }
    var _res = await AppHelper.api('property/update', 'POST', { uid: oUser.uid, data:_data });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
      return;
    }
    setMsgBox({...msgBox, open: true, msg: 'SUCCESS: Information updated!'});
    navigate('/property/detail/' + property.pid);
  }
  const onAddCancel = ()=>{
    navigate('/property/detail/' + property.pid);
  };
  const isDataOK = ()=>{
    var _validateOK = true;
    var _name = txtName.current.value;
    var _seller = txtSeller.current.value;
    if((!_name || _name.length<1)){
      _validateOK =  false;
      setTxtNameOK(false);
    }
    else setTxtNameOK(true);
    if(!_seller || _seller.length<1){
      _validateOK =  false;
      setTxtSellerOK(false);
    }
    else setTxtSellerOK(true);
    if(formattedPhoneNumber!='' && !formattedPhoneNumber){
      _validateOK = false;
      setTxtPhoneNoOK(false);
    }
    else setTxtPhoneNoOK(true);

    if(!address.address || address.address.length<1){
      _validateOK = false;
      setTxtPostalCodeOK(false);
    }
    else setTxtPostalCodeOK(true);
    return _validateOK;
  };
  const handlePostalCodeChange = async(arg) =>{
    setAddress({country:'', lat:0, lng:0, address:''});
    var _postalCode = txtPostalCode.current.value;
    if(_postalCode.length<6){
      setTxtPostalCodeOK(false);
      return;
    }
    var _res = await AppHelper.api('api/addressGet', 'POST', { address: _postalCode });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to get the address!'});
      setTxtPostalCodeOK(false);
      return;
    }
    if(_res.data && _res.data.address && _res.data.address.length>0){
      setAddress({country: _res.data.country, lat: _res.data.lat, lng: _res.data.lng, address: _res.data.address});
      setTxtPostalCodeOK(true);
    }
  };

  return (
    <>
      <AppTopBar />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'75vh', xs:'75vh'} }} elevation={3}>
          <Box p={2} pl={2} pr={2} alignItems="flex-start">
            <ListItem disablePadding>
              <ListItemText
                primary={
                  <React.Fragment>
                    <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>
                      {isEdit? 'EDIT LISTING':'NEW LISTING'}
                    </span>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {/* for {oUser.name} */}
                    </Typography>
                    {" — " + oUser.displayName}
                  </React.Fragment>
                }
              />
              <ListItemAvatar sx={{minWidth:70, display:'flex', justifyContent:'center'}}>
                <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
              </ListItemAvatar>
            </ListItem>
            <Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1}>
                  <TextField inputRef={txtName} error={!txtNameOK} label="LISTING NAME" variant="outlined" fullWidth />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1}>
                  <TextField inputRef={txtSeller} error={!txtSellerOK} label="SELLER NAME" variant="outlined" fullWidth />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1.5}>
                  <FormControl fullWidth>
                    <InputLabel id='lblDdlCountry'>Country</InputLabel>
                    <Select labelId='lblDdlCountry' label="Country"
                      value={countryCode}
                      onChange={handleCountrySelect}
                    >
                      {countryList.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box m={.5} pt={1}>
                  <TextField onChange={handlePhoneNoChange} error={!txtPhoneNoOK} id="txtPhoneNo" inputRef={txtPhoneNo} label="Phone Number" variant="outlined" fullWidth />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1}>
                  <TextField onChange={handlePostalCodeChange} error={!txtPostalCodeOK} inputRef={txtPostalCode} label="POSTAL CODE" variant="outlined" fullWidth />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1}>
                  <span style={{opacity:0.5, fontStyle:'italic'}}>{address.address}</span>
                </Box>
              </Grid>
              <Grid container pt={.5}>
                <Grid item xs={6}>
                  <Button onClick={onAddCancel} color="secondary">CANCEL</Button>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    { !isEdit && <Button onClick={onAddSubmit} color="info" variant="outlined">NEXT</Button> }
                    { isEdit && <Button onClick={onUpdate} color="info" variant="outlined">SAVE</Button> }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </>
  );
}
