import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar';
import { Avatar, Container, Checkbox, FormControlLabel, FormGroup,
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Button, Select, MenuItem, FormControl, InputLabel, Grid, Paper, Typography 
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useUser } from '../UserContext';
import { AppHelper } from '../AppHelper';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useProperty } from '../contexts/context-property';

function PropertyListItems({ items, funOnClick, calendarActive }) {
  return (
    <List>
      {items.map((item, index) => (
        <ListItem key={item.id} disablePadding>
          <ListItemButton onClick={() => funOnClick(item)}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={item.n} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default function AgentSchedule() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [viewCode, setViewCode] = useState('NEW');
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });

  const _useProperty = useProperty();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const dateMin = dayjs(new Date());
  const dateMax = dayjs(new Date()).add(2, 'months');
  
  const bufferTimeList = [
    {v:0, n: '-- Select --'}, {v:5, n: '5 mins'}, {v:10, n: '10 mins'}, {v:15, n: '15 mins'}, {v:20, n: '20 mins'}, {v:25, n: '25 mins'},  {v:30, n: '30 mins'}
  ];
  const showTimeList = [
    {v:0, n: '-- Select --'}, {v:5, n: '5 mins'}, {v:10, n: '10 mins'}, {v:15, n: '15 mins'}, {v:20, n: '20 mins'}, {v:30, n: '30 mins'}, {v:60, n: '60 mins'}
  ];
  const [bufferTime, setBufferTime] = useState(0);
  const [showTime, setShowTime] = useState(0);
  const [bufferTimeOK, setBufferTimeOK] = useState(true);
  const [showTimeOK, setShowTimeOK] = useState(true);
  const [dateFromOK, setDateFromOK] = useState(true);
  const [dateToOK, setDateToOK] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyTime, setIsAnyTime] = useState(false);
  const [availList, setAvailList] = useState([]); //[ { d:'MON', hf:9, ht:17 } ]
  
  useEffect(()=>{
    if(!_useProperty.property){
      navigate('/property/list');
      return;
    }
    if(_useProperty.property.pid && _useProperty.property.pid.length>1){
      setIsEdit(true);
      const fetchAgSh = async() =>{
        var _res = await AppHelper.api('property/item', 'POST', {uid:_useProperty.property.uid, code:'C', pid:_useProperty.property.pid});
        if(_res.status !== 1 || !_res.data || !_res.data.agSh){
          setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the Agent Schedule!'});
          return;
        }
        setDateFrom(dayjs(_res.data.agSh.ds));
        setDateTo(dayjs(_res.data.agSh.de));
        setBufferTime(_res.data.agSh.tBuff);
        setShowTime(_res.data.agSh.tShow);
        setAvailList(_res.data.agSh.avail);
      };
      fetchAgSh();
    }
  }, []);

  const onAddSubmit = async () => {
    if(!isFormOK()) return;
    var _agSh = agShGet();
    var _res = await AppHelper.api('property/add', 'POST', { uid: oUser.uid, data:_useProperty.property, agSh: _agSh });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
      return;
    }
    _useProperty.property.pid = _res.data.id;
    _useProperty.property.sid = _res.data.sid;
    _useProperty.setProperty(_useProperty.property);
    navigate('/property/created');
  };
  const onUpdate = async()=>{
    if(!isFormOK()) return;
    var _agSh = agShGet();
    var _res = await AppHelper.api('property/agSh', 'POST', { uid: oUser.uid, pid:_useProperty.property.pid, agSh: _agSh });
    if(_res.status != 1){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to save!'});
      return;
    }
    navigate(`/property/detail/${_useProperty.property.pid}`);
  };
  const agShGet = ()=>{
    var _agSh = {
      ds: dayjs(dateFrom).format('YYYY-MM-DD'),
      de: dayjs(dateTo).format('YYYY-MM-DD'),
      tBuff: bufferTime,
      tShow: showTime,
      any: isAnyTime ? 1 : 0
    }
    if(!_agSh.any){
      _agSh.avail = [];
      availList.forEach(a=>{
        _agSh.avail.push({ d: a.d, hf:a.hf, ht:a.ht });
      });
    }
    return _agSh;
  }
  const isFormOK = ()=>{
    var _ok = true;
    if(dateFrom==null){
      setMsgBox({...msgBox, open: true, msg: 'Please input the FROM DATE!'});
      setDateFromOK(false);
      _ok = false;
    }
    if(!dateTo){
      setDateToOK(false);
      if(_ok) setMsgBox({...msgBox, open: true, msg: 'Please input the TO DATE!'});
      _ok = false;
    }
    if(bufferTime<1){
      setBufferTimeOK(false);
      _ok = false;
    }
    if(showTime<1){
      setShowTimeOK(false);
      _ok = false;
    }
    return _ok;
  }
  const onAddCancel = ()=>{
    if(isEdit)
      navigate('/property/detail/' + _useProperty.property.pid);
    else navigate('/property/list');
  };
  const bufferTimeSelect = async(arg) =>{
    setBufferTime(arg.target.value);
    setBufferTimeOK(bufferTime>0);
  };
  const showTimeSelect = async(arg) =>{
    setShowTime(arg.target.value);
    setShowTimeOK(showTime>0);
  };
  const onAnyTimeChecked = ()=>{
    var _isAnyTime = !isAnyTime;
    setIsAnyTime(_isAnyTime);
    if(_isAnyTime){
      setAvailList([ { d:'MON', hf:9, ht:17 } ]);
    }
  };
  const availDayChoose = (argEvent, arg, argIndex)=>{
    var _availList = [...availList];
    _availList[argIndex].d = argEvent.target.value;
    setAvailList(_availList);
  }
  const availHfChoose = (argEvent, arg, argIndex)=>{
    var _availList = [...availList];
    var _item = _availList[argIndex];
    if(!_item) return;
    _item.hf = argEvent.target.value;
    if(_item.hf > _item.ht)
      _item.ht = _item.hf;
    setAvailList(_availList);
  }
  const availHtChoose = (argEvent, arg, argIndex)=>{
    var _availList = [...availList];
    var _item = _availList[argIndex];
    if(!_item) return;
    _item.ht = argEvent.target.value;
    if(_item.hf > _item.ht)
      _item.hf = _item.ht;
    setAvailList(_availList);
  }
  const availDayAdd = ()=>{
    var _availList = [...availList];
    _availList.push({d:'MON', hf:9, ht:17});
    setAvailList(_availList);
  };
  const availDayRemove = (argIndex)=>{
    var _availList = [...availList];
    _availList.splice(argIndex, 1);
    setAvailList(_availList);
  };
  const dateFromChoose = (arg)=>{
    setDateFrom(arg);
    if(dateTo){
      if(dayjs(arg).isAfter(dateTo))
        setDateTo(arg);
      dateFromToChoose(arg, dateTo);
    }
  }
  const dateToChoose = (arg)=>{
    setDateTo(arg);
    if(dateFrom){
      if(dayjs(arg).isBefore(dateFrom))
        setDateFrom(arg);
      dateFromToChoose(dateFrom, arg);
    }
  }
  const dateFromToChoose = (date1, date2) =>{
    if(isEdit) return;
    var _sDate = dayjs(date1);
    var _eDate = dayjs(date2);
    var _availList = [];
    while(_eDate.isSame(_sDate) || _eDate.isAfter(_sDate)){
      var _ddd = _sDate.format('ddd').toUpperCase();
      var _wdIndex = _sDate.day();
      if(_wdIndex==0) _wdIndex=7;
      if(!_availList.find(r=>r.d==_ddd)){
        _availList.push({ d:_ddd, hf:9, ht:17, wdi:_wdIndex });
      }
      _sDate = _sDate.add(1, 'day');
    }
    _availList = AppHelper.sortList(_availList, 'wdi');
    setAvailList(_availList);
  }
  const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  const hourList = Array.from({ length: 24 }, (_, i) => {
    const hour = dayjs().startOf('day').hour(i);
    return {n:hour.format('hh A'), v:hour.hour()};
  });

  return (
    <div>
      <AppTopBar2 />
      {viewCode=='NO'? (
        <div></div>
      ):(
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'80vh', xs:'75vh'} }} elevation={3}>
          <Box p={2} alignItems="flex-start">
            <ListItem>
              <ListItemText sx={{minWidth:200}}
                primary={
                  <React.Fragment>
                    <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>AGENT SCHEDULE</span>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                    </Typography>
                    {" — " + oUser.displayName}
                  </React.Fragment>
                }
              />
              <ListItemAvatar>
                <Avatar src={oUser.picture} referrerPolicy="no-referrer" />
              </ListItemAvatar>
            </ListItem>
            <Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1}>
                  <DatePicker sx={{width:'100%'}}
                    label="LISTING START DATE"
                    minDate={dateMin} maxDate={dateMax}
                    value={dateFrom}
                    onChange={(newDateFrom) => dateFromChoose(newDateFrom)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1}>
                  <DatePicker error={true} sx={{width:'100%'}}
                    label="LISTING END DATE"
                    minDate={dateMin} maxDate={dateMax}
                    value={dateTo}
                    onChange={(newDateTo) => dateToChoose(newDateTo)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1.5}>
                  <FormControl fullWidth error={!bufferTimeOK}>
                    <InputLabel id='lblDdlBuffer'>BUFFER TRAVEL TIME</InputLabel>
                    <Select labelId='lblDdlBuffer'
                      value={bufferTime}
                      onChange={bufferTimeSelect}
                      label="BUFFER TRAVEL TIME"
                    >
                      {bufferTimeList.map((item) => (
                        <MenuItem key={item.v} value={item.v}>
                          {item.n}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={.5} pt={1.5}>
                  <FormControl fullWidth error={!showTimeOK}>
                    <InputLabel id='lblDdlBuffer'>SHOWING DURATION</InputLabel>
                    <Select labelId='lblDdlBuffer'
                      value={showTime}
                      onChange={showTimeSelect}
                      label="SHOWING DURATION"
                    >
                      {showTimeList.map((item) => (
                        <MenuItem key={item.v} value={item.v}>
                          {item.n}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={8} display='flex' alignItems='center' sx={{color:'rgba(0,0,0,0.45)'}}>
                    AGENT AVAILABILITY
                    { !isAnyTime &&
                      <IconButton onClick={availDayAdd} aria-label="delete">
                        <Icon color="primary">add_circle</Icon>
                      </IconButton>
                    }
                  </Grid>
                  <Grid item xs={4} display='flex' justifyContent='flex-end'>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={isAnyTime} onChange={onAnyTimeChecked} />} label="ANYTIME" />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              {!isAnyTime &&
                <div>
                  {availList && availList.map((av, iAv)=>(
                    <Grid key={'AV_G_'+iAv} container>
                      <Grid item xs={3}>
                        <FormControl size="small">
                          <Select value={av.d || ''} variant="standard" fullWidth
                            onChange={(event)=>availDayChoose(event, av, iAv)}
                          >
                            {/* <MenuItem key={'_'+iAv} value='-'><em>SELECT</em></MenuItem> */}
                            {daysOfWeek.map(dw=>(
                              <MenuItem key={dw+iAv} value={dw}>{dw}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={7}>
                        <Box display='flex'>
                          <FormControl size="small">
                            <Select value={av.hf || 0} variant="standard"
                              onChange={(event)=>availHfChoose(event, av, iAv)}
                            >
                              {hourList.map(hr=>(
                                <MenuItem key={hr.n} value={hr.v}>{hr.n}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div style={{display:'flex', alignItems:'center', margin:'0 8px 0 5px', fontSize:'10px', letterSpacing:'1', fontStyle:'italic'}}>TO</div>
                          <FormControl size="small">
                            <Select value={av.ht || 0} p={0} variant="standard"
                              onChange={(event)=>availHtChoose(event, av, iAv)}
                            >
                              {hourList.map(hr=>(
                                <MenuItem key={hr.n} value={hr.v}>{hr.n}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </Box>
                      </Grid>
                      <Grid item xs={2} display='flex' justifyContent='flex-end'>
                        {availList.length>1 &&
                        <IconButton onClick={()=>availDayRemove(iAv)}>
                          <Icon color="warning" sx={{opacity:0.7}}>remove_circle_outline</Icon>
                        </IconButton> 
                        }
                      </Grid>
                    </Grid>
                  ))}
                </div>
              }
              <Grid container mt={2} spacing={0}>
                <Grid item xs={6} sm={6}>
                  {/* <Paper elevation={3}>
                    <div>Column 1 Content</div>
                  </Paper> */}
                  <Box m={.5} display="flex" justifyContent="flex-start">
                    <Button onClick={onAddCancel} color="secondary">BACK</Button>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {/* <Paper elevation={3}>
                    <div>Column 2 Content</div>
                  </Paper> */}
                  <Box m={.5} display="flex" justifyContent="flex-end">
                    {isEdit?(
                      <Button onClick={onUpdate} color="info" variant="outlined">UPDATE</Button>
                    ):(
                      <Button onClick={onAddSubmit} color="info" variant="outlined">SAVE</Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        </Container>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </div>
  );
}
