import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import '../css/property-list.css';
import { Container, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Button, Paper, Typography, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from "dayjs";

function BuyerList({ items, funOnEdit, funOnShare }) {
  return (
    <List>
      {items.map((item, index) => (
        <div key={item.bid}>
          <ListItem key={item.bid} disablePadding>
            <Grid container>
              <Grid item xs={7} sx={{paddingLeft:2}}>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', height:'100%'}}>
                  <div>
                    {item.n + (item.ag===1?' (Agent)':'')}<br/>
                    {/* <span style={{fontStyle:'italic', opacity:0.65}}>{item.bStatus}</span> */}
                    <span style={{fontStyle:'italic', fontSize:'small', color:item.bStatus&&item.bStatus.includes('Pending')?'#6f0202':'inherit', opacity:0.65}}>{item.bStatus}</span>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'flex-end' }}>
                  <Button onClick={() => funOnEdit(item)} color="info" variant="outlined" 
                    sx={{paddingTop:0, paddingBottom:0, paddingLeft:0, paddingRight:0, marginTop:0.5, marginBottom:0.5}}>EDIT
                  </Button>
                  <IconButton onClick={() => funOnShare(item)}>
                    <ContentCopyIcon />
                  </IconButton>
                  {/* <IconButton onClick={go2Edit} sx={{border:'1px solid #c0c0c0'}}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={go2Share}>
                    <ContentCopyIcon />
                  </IconButton> */}
                </Box>
              </Grid>
            </Grid>
            {/* <ListItemButton sx={{minWidth:300}}>
              <ListItemText
                primary={item.n + (item.ag===1?' (Agent)':'')}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                    </Typography>
                    <span style={{marginLeft:0, fontStyle:'italic', color:'#bbb'}}>Pending Appointment</span>
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
            </ListItemButton> */}
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
}

export default function PagePropertyDetail() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { pid } = useParams();
  
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [readingData, setReadingData] = useState(true);
  const [record, setRecord] = useState({});

    // useEffect(()=>{
    //   var _arg = { accessToken: oUser.accessToken };
    //   AppHelper.api('user/calendar', 'POST', _arg).then(_resCalendar=>{
    //     console.log('res', _resCalendar);
    //   });
    //});
  const { setProperty } = useProperty();

  useEffect(()=>{
    setReadingData(true);
    const fetchRecords = async() =>{
      setReadingData(false);
      var _res = await AppHelper.api('property/detail', 'POST', {pid:pid});
      if(_res.status !== 1){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load the data!'});
        navigate('/home');
        return;
      }
      if(!_res.data){
        setMsgBox({...msgBox, open: true, msg: 'ERROR: Invalid Property!'});
        navigate('/home');
        return;
      }
      var _item = _res.data;
      _item.pid = pid;
      _item.sStatus = AppHelper.itemSStatus(_item.sProfile && _item.sProfile.slSh);
      if(_item.b && _item.b.length>0){
        for(var i=0; i<_item.b.length; i++){
          var _b = _item.b[i];
          if(_b.bkDate){
            var _bDate = dayjs(_b.bkDate).format('DD MMM \'YY');
            _b.bStatus = `Booked on ${_bDate}`;
          }
          else{
            _b.bStatus = 'Pending Booking';
          }
        }
      }
      setRecord(_item);
      setProperty(_item);
    };
    fetchRecords();
  }, []);

  const onAddBuyer = async () => {
    setProperty(record);
    navigate('/buyer/add');
  };
  const onAddBuyerAgent = async () => {
    setProperty(record);
    navigate('/buyer/add/1');
  };
  const onBuyerEdit = async (arg) => {
    var _isAgent = arg.ag === 1 ? 1 : 0;
    navigate(`/buyer/add/${_isAgent}/${arg.bid}`);
  };
  const onBuyerShare = async (arg) => {
    navigate(`/buyer/added/${arg.bid}`);
  };
  const go2Share = ()=>{
    //console.log('record', record);
    setProperty(record);
    navigate('/property/created');
  }
  const go2Edit = ()=>{
    setProperty(record);
    navigate('/property/' + record.pid);
  }
  const go2Schedule = ()=>{
    setProperty(record);
    navigate('/agent/schedule');
  }
  
  return (
    <div>
      <AppTopBar />
      {readingData?(
        <AppLoading />
      ):(
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper sx={{ my: { xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'80vh', xs:'75vh'} }} elevation={3}>
            <Box p={0} pl={0} pr={0} alignItems="flex-start">
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ minHeight: '40vh' }}
              >
                <Box sx={{width:'100%' }}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <span style={{fontWeight:'bold', fontSize:'1.3rem'}}>PROPERTY DETAILS</span>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                          </Typography>
                          {record.n}
                        </React.Fragment>
                      }
                    />
                    <ListItemAvatar>
                      <IconButton onClick={go2Edit} sx={{border:'1px solid #c0c0c0'}}>
                        <EditIcon />
                      </IconButton>
                      {/* <Avatar src={oUser.picture} referrerPolicy="no-referrer" /> */}
                    </ListItemAvatar>
                  </ListItem>
                </Box>
                <Box sx={{width:'100%'}}>
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid item xs={7}>
                        SELLER — {record.s}<br/>
                        {/* <span style={{fontStyle:'italic', opacity:0.65}}>{record.sStatus}</span> */}
                        <span style={{fontStyle:'italic', fontSize:'small', color:record.sStatus&&record.sStatus.includes('Pending')?'#6f0202':'inherit', opacity:0.65}}>{record.sStatus}</span>
                      </Grid>
                      <Grid item xs={5}>
                        <Box sx={{display:'flex', alignItems:'flex-end', justifyContent:'flex-end' }}>
                          <IconButton onClick={go2Schedule}>
                            <CalendarMonthIcon />
                          </IconButton>
                          {/* <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                            <Button onClick={onEdit} color="info" variant="outlined" sx={{paddingTop:0, paddingBottom:0, paddingLeft:1, paddingRight:1}}>EDIT</Button>
                          </div> */}
                          {/* <IconButton onClick={go2Edit} sx={{border:'1px solid #c0c0c0'}}>
                            <EditIcon />
                          </IconButton> */}
                          <IconButton onClick={go2Share}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>
                
                {record.b && record.b.length>0?(
                  <Box sx={{width:'100%'}}>
                    <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <span style={{fontWeight:'bold'}}>BUYER{record.b.length>1?'S':''} ({record.b.length})</span>
                      </Grid>
                    </Grid>
                    </ListItem>
                    <Divider />
                    <BuyerList items={record.b} funOnEdit={onBuyerEdit} funOnShare={onBuyerShare} />
                  </Box>
                ):(
                  <div></div>
                )}
                <Grid item pt={1.5} sx={{display:'flex', width:'100%', flexDirection:'column'}}>
                  <Box sx={{width: '100%'}} m={.5} display="flex" justifyContent="flex-end" alignItems='flex-end' flex={10}>
                    <Button onClick={onAddBuyer} color="info" variant="outlined" sx={{marginRight:2}}>ADD BUYER</Button>
                  </Box>
                  <Box sx={{width: '100%'}} m={.5} display="flex" justifyContent="flex-end" alignItems='flex-end' flex={10}>
                    <Button onClick={onAddBuyerAgent} color="info" variant="outlined" sx={{marginRight:2}}>ADD BUYER <br/>AGENT</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      )}
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </div>
  );
}
